import React, { useState } from 'react'
import styles from './EditBlog.module.scss'
import Notiflix from 'notiflix';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { db, storage } from '../../../firebase/config';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Divider } from '@mui/material';
import Loader from '../../loader/Loader';
import { Timestamp, addDoc, collection, doc, setDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { selectBlogs, selectPosts } from '../../../redux/slice/utilSlice';

const EditBlog = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const {id} = useParams()
    const blogs = useSelector(selectBlogs);
    const blogEdit = blogs.find((item) => item.id === id)
  
    const [currentBlog, setCurrentBlog] = useState(blogEdit);
    const [itemOnBlogInput, setItemOnBlogInput] = useState('');

    const handleInputItemOnBlog = (e) => {
        setItemOnBlogInput(e.target.value);
  }

    const editBlog = (e) => {
      e.preventDefault()
      setIsLoading(true)
      try{
          setDoc(doc(db, "blogs", id), {
              editedAt: Timestamp.now().toDate(),
              itemOnBlog: currentBlog.itemOnBlog ? currentBlog.itemOnBlog : []
      }, {merge: true})
          setIsLoading(false)
          toast.success("Blog edited successfully.")
          navigate("/admin/blogmanagement")
      } catch (error) {
          setIsLoading(false)
          toast.error(error.message)
      }
  }

  const handleAddItemBlog = () => {
    const prevState = currentBlog.itemOnBlog.slice()
    prevState.push({id: itemOnBlogInput})
    const newObj = { ...currentBlog, itemOnBlog: prevState };
    setCurrentBlog(newObj)
    toast.success("Item on blog Added Successfully.")
  }

  const handleDeleteItemBlog = (id) => {
    const prevState = currentBlog.itemOnBlog.slice()
    const findIndex = prevState.findIndex((item) => item.id === id)
    const newState = prevState.splice(0, findIndex)
    const newObj = { ...currentBlog, itemOnBlog: newState };
    setCurrentBlog(newObj)
    toast.success("Item on blog Deleted Successfully.");
  }
    
  return (
    <>
    {isLoading && <Loader />}
    <div className={styles.editblog}>
        <h4>Edit Blog</h4>
        <div className={styles.newpostinput}>
            <p className={styles.inputtitle}>
                {currentBlog.slug}
            </p>

            <div className={styles.itemonposts}>
              <Divider />
              <p className={styles.title}>ITEM ON BLOG</p>
              {currentBlog.itemOnBlog?.map((item, index) => {
                const {id} = item;
                return (
                  <div key={index} className={styles.listitem}>
                    <p>{id}</p>
                    <div className={styles.button}>
                      <Button variant='contained' onClick={() => handleDeleteItemBlog(id)}>
                        DELETE
                      </Button>
                    </div>
                  </div>
                )
              })}
              <div className={styles.inputdiv}>
                <input
                  type='text'
                  name="itemonblog"
                  placeholder='Insert item id'
                  value={itemOnBlogInput}
                  className={styles.textbox}
                  onChange={e => handleInputItemOnBlog(e)}
                />
                <div className={styles.button}>
                  <Button variant='contained' onClick={() => handleAddItemBlog()}>
                    ADD
                  </Button>
                </div>
              </div>
            </div>

            <div className={styles.bottombutton}>
                <div className={styles.button}>
                    <Button variant='contained' onClick={editBlog}>
                            OK
                    </Button>
                </div>
                <div className={styles.button}>
                    <Button variant='outlined' onClick={() => navigate('/admin/blogmanagement')}>
                        Cancel
                    </Button>
                </div>
            </div>


            
        </div>
    </div>
    </>
  )
}

export default EditBlog