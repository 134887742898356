import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoggedIn: false,
    email: null,
    phoneNumber: null,
    userName: null,
    userID: null,
    address: []
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_ACTIVE_USER: (state, action) => {
        const {email, userName, userID, phoneNumber} = action.payload
        state.isLoggedIn = true
        state.email = email
        state.userName = userName
        state.userID = userID
        state.phoneNumber = phoneNumber
    },
    SET_ACTIVE_EMAIL: (state, action) => {
      const {email} = action.payload
      state.email = email
    },
    SET_ACTIVE_PHONENUMBER: (state, action) => {
      const {phoneNumber} = action.payload
      state.phoneNumber = phoneNumber
    },
    REMOVE_ACTIVE_USER(state, action) {
        state.isLoggedIn = false
        state.email = null
        state.userName = null
        state.userID = null
        state.phoneNumber = null
    },
    STORE_ADDRESS(state, action) {
      state.address = action.payload
    }
  }
});

export const {SET_ACTIVE_USER, REMOVE_ACTIVE_USER, STORE_ADDRESS, SET_ACTIVE_EMAIL, SET_ACTIVE_PHONENUMBER} = authSlice.actions

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn
export const selectEmail = (state) => state.auth.email
export const selectPhoneNumber = (state) => state.auth.phoneNumber
export const selectUserName = (state) => state.auth.userName
export const selectUserID = (state) => state.auth.userID
export const selectAddress = (state) => state.auth.address

export default authSlice.reducer