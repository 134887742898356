import ReactDOM from 'react-dom'
import styles from "./Loader.module.scss"
import { CircularProgress } from '@mui/material'

const Loader = () => {
  return ReactDOM.createPortal (
    <div className={styles.wrapper}>
        <div className={styles.loader}>
          <CircularProgress color="inherit" />
        </div>
    </div>,
    document.getElementById("loader")
  )
}

export default Loader