import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import styles from "./OrderQRDetails.module.scss"
import useFetchDocument from '../../../customHook/useFetchDocument'
import { Button, CircularProgress, Divider } from '@mui/material'
import { addDoc, collection, deleteDoc, doc } from 'firebase/firestore'
import { db } from '../../../firebase/config'

const OrderQRDetails = () => {
    const [order, setOrder] = useState(null)
    const {id} = useParams()
    const navigate = useNavigate();

    const { document } = useFetchDocument("ordersQR", id)

    useEffect(() => {
        setOrder(document)
    },[document])

    const handleSaveToOrder = async () => {
        const orderConfig = {
            userID: order.userID,
            userEmail: order.userEmail,
            orderDate: order.orderDate,
            orderTime: order.orderTime,
            orderAmount: order.orderAmount,
            shippingCost: order.shippingCost,
            shippingMethod: order.shippingMethod,
            paymentMethod: order.paymentMethod,
            orderStatus: "Pending",
            cartItems: order.cartItems,
            shippingAddress: order.shippingAddress,
            billingAddress: order.billingAddress,
            pickupDate: order.pickupDate,
            pickupTime: order.pickupTime,
            noteMsg: order.noteMsg,
            createdAt: order.createdAt,
            branch: order.branch
        }

        const deleteCart = async item => {
            try {
            await deleteDoc(doc(db, "cart", order.userID, 'cartItems', item.id));
            } catch (error) {
            console.log(error)
            }
        };

        try {
            await addDoc(collection(db, "orders"), orderConfig)
            
            try {
            order.cartItems.map(item => {
                deleteCart(item)
            });
            } catch (error) {
            console.log(error);
            }
            
        } catch (error) {
            console.log(error)
        }
    };

    const handleDeleteOrder = async () => {
        try {
            await deleteDoc(doc(db, "ordersQR", order.id));
            navigate('/admin/orders')
        } catch (error) {
            console.log(error)
        }
    }


  return (
    <>
        <div className={styles.table}>
            <h2>Order QR Details</h2>
            <div>
                <Link to="/admin/orders">
                    &larr; Back To Orders
                </Link>
            </div>
            <br />
            {order === null ? (
                <div className={styles.loader}>
                    <CircularProgress color="inherit" />
                </div> 
            ) : (
                <>
                    <p>
                        <b>Order ID</b> {order.id}
                    </p>
                    <p>
                        <b>Order Amount</b> THB{order.orderAmount}
                    </p>
                    <p>
                        <b>Order Status</b> {order.orderStatus}
                    </p>
                    <p>
                        <b>Payment Method</b> {order.paymentMethod}
                    </p>
                    <p>
                        <b>Shipping Method</b> {order.shippingMethod}
                    </p>
                    {order?.shippingMethod === 'express' ? (
                        <>
                        <p>
                            <b>Express Delivery ID</b> {order?.expressOrderId}
                        </p>
                        <p>
                            <b>Express Delivery Status</b> {order?.expressOrderStatus}
                        </p>
                        </>
                    ) : null}
                    {order?.shippingMethod === 'pickup' || order?.shippingMethod === 'expressPickup' ? (
                        <>
                        <p>
                            <b>Pickup Date</b> {order?.pickupDate}
                        </p>
                        <p>
                            <b>Pickup Time</b> {order?.pickupTime}
                        </p>
                        </>
                    ) : null}
                    <p>
                        <b>E-mail</b> {order.userEmail}
                    </p>
                    <p>
                        <b>Shipping Address</b>
                        <br />
                        Name: {order.shippingAddress.name} {order.shippingAddress.name2}
                        <br />
                        Address: {order.shippingAddress.line1}, {order.shippingAddress.line2},{order.shippingAddress.city},
                        <br />
                        State: {order.shippingAddress.state}
                        <br />
                        Country: {order.shippingAddress.country}{', '}{order.shippingAddress.postal_code}
                        <br />
                        Phone: {order.shippingAddress.prefix}{order.shippingAddress.phone}

                    </p>
                    <p>
                        <b>Note Message:</b> {order.noteMsg}
                    </p>
                    <br />
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>PRICE</th>
                                <th>QTY</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {order.cartItems.map((cart,index) => {
                                const {id, name, price, imageURL, cartQuantity} = cart
                                return (
                                    <tr key={id}>
                                        <td>
                                            <b>{index + 1}</b>
                                        </td>
                                        <td>
                                            <p>
                                                <b>{name}</b>
                                            </p>
                                            <img src={imageURL[0].imageURL} alt={name} style={{width:"100px"}} />
                                        </td>
                                        <td>{`THB${price}`}</td>
                                        <td>{cartQuantity}</td>
                                        <td>{`THB${(price * cartQuantity).toFixed(2)}`}</td>
                                        
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </>
            )}
            <div className={styles.btndiv}>
                <Divider style={{width: '100%'}} />
                <div className={styles.content}>
                    <Button style={{fontSize: 16, color: 'black'}} onClick={() => handleSaveToOrder()}>
                        Save to Order
                    </Button>
                    <Button style={{fontSize: 16, color: 'black', marginLeft: 20}} onClick={() => handleDeleteOrder()}>
                        Remove
                    </Button>
                </div>

            </div>
        </div>
    </>
  )
}

export default OrderQRDetails