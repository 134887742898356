import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    
    cartItems: [],
    cartTotalQuantity: 0,
    cartTotalAmount: 0,
    shippingCost: 0,
    expressShipping: 0,
    regularShipping: 0,
    grandTotal: 0,
    pickupDate: "",
    todayDate: "",
    minShippingDate: "",
    maxShippingDate: "",
    shippingMethod: "",
    previousURL: "",
    isShowPanel : false,
    cartPanelItem : [],
    favItem: [],
    sflItem: [],
    deliveryid: '',
}

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    STORE_CARTITEM(state, action) {
    // console.log(action.payload);
    const cartData = action.payload;
    state.cartItems = cartData;
    },
    STORE_FAVITEM(state, action) {
    const favData = action.payload;
    state.favItem = favData;
    },
    STORE_SFLITEM(state, action) {
    const sflData = action.payload;
    state.sflItem = sflData;
    },
    ADD_TO_FAV(state, action) {
    const favIndex = state.favItem.findIndex(
        item => item.id === action.payload.id,
    );
    if (favIndex < 0) {
        state.favItem.push(action.payload);
    }
    },
    REMOVE_FROM_FAV(state, action) {
    const favIndex = state.favItem.findIndex(
        item => item.id === action.payload.id,
    );
    if (favIndex >= 0) {
        const newFavItem = state.favItem.filter(
        item => item.id !== action.payload.id,
        );
        state.favItem = newFavItem;
    }
    },
    ADD_TO_SFL(state, action) {
    const sflIndex = state.sflItem.findIndex(
        item => item.id === action.payload.id,
    );
    if (sflIndex < 0) {
        state.sflItem.push(action.payload);
    }
    },
    REMOVE_FROM_SFL(state, action) {
    const sflIndex = state.sflItem.findIndex(
        item => item.id === action.payload.id,
    );
    if (sflIndex >= 0) {
        const newSflItem = state.sflItem.filter(
        item => item.id !== action.payload.id,
        );
        state.sflItem = newSflItem;
    }
    },
    ADD_TO_CART(state, action) {
        //console.log(action.payload)
        const productIndex = state.cartItems.findIndex((item) => item.id === action.payload.id)
        if (productIndex >= 0) {
            //Item already exists in the cart
            //Increase the cartQuantity
            state.cartItems[productIndex].cartQuantity += 1;

        } else {
            //Item doesn't exists in the cart
            //Add item to the cart
            const tempProduct = {...action.payload, cartQuantity: 1}
            state.cartItems.push(tempProduct)
        }
        
    },

    DECREASE_CART(state, action) {
        const productIndex = state.cartItems.findIndex((item) => item.id === action.payload.id)
        if (state.cartItems[productIndex].cartQuantity > 1) {
            state.cartItems[productIndex].cartQuantity -= 1

        } else if (state.cartItems[productIndex].cartQuantity === 1) {
            const newCartItem = state.cartItems.filter((item) => item.id !== action.payload.id)
            state.cartItems = newCartItem
        }
    },
    REMOVE_FROM_CART(state, action) {
        const newCartItem = state.cartItems.filter((item) => item.id !== action.payload.id)
        state.cartItems = newCartItem
    },
    CLEAR_CART(state, action) {
        state.cartItems = []
    },
    CALCULATE_SUBTOTAL(state, action) {
        const array = []
        state.cartItems.map((item) => {
            const {price, cartQuantity} = item
            const cartItemAmount = price * cartQuantity
            return array.push(cartItemAmount)
        })
        const totalAmount = array.reduce((a, b) => {
            return a + b
        }, 0)
        state.cartTotalAmount = totalAmount
    },
    CALCULATE_TOTAL_QUANTITY(state, action) {
        const array = []
        state.cartItems.map((item) => {
            const { cartQuantity } = item
            const quantity = cartQuantity
            return array.push(quantity)
        })
        const totalQuantity = array.reduce((a, b) => {
            return a + b
        }, 0)
        state.cartTotalQuantity = totalQuantity

    },
    SAVE_DELIVERY_ID(state, action) {
        state.deliveryid = action.payload
    },
    CALCULATE_EXPRESS_DELIVERY_COST(state, action) {
        state.expressShipping = action.payload
    },
    CALCULATE_REGULAR_DELIVERY_COST(state, action) {
        const cartAmount = state.cartTotalAmount
        if (cartAmount >= 1000) {
            state.regularShipping = 0;
        } 
        if (cartAmount < 1000) {
            state.regularShipping = 90;
        }
    },
    CALCULATE_SHIPPING_COST(state, action) {

        const method = action.payload
        if (method === 'express') {
            state.shippingCost = state.expressShipping
            state.shippingMethod = "express"
        }
        if (method === 'expressPickup') {
            state.shippingCost = 0
            state.shippingMethod = "expressPickup"
        }
        if (method === 'delivery') {
            state.shippingCost = state.regularShipping
            state.shippingMethod = "delivery"
        }
        if (method === 'pickup') {
            state.shippingCost = 0
            state.shippingMethod = "pickup"
        }
    },

    CALCULATE_GRAND_TOTAL(state, action) {
        
        state.grandTotal = state.shippingCost + state.cartTotalAmount
        
    },
    CALCULATE_PICKUP_DATE(state, action) {
        const pickup = new Date()
        const maxpickup = new Date()
        pickup.setDate(pickup.getDate() + 1);
        maxpickup.setDate(pickup.getDate() + 7);
        state.pickupDate = pickup.toLocaleDateString('th-TH') + ' - ' + maxpickup.toLocaleDateString('th-TH')
    },
    CALCULATE_MIN_SHIPPING_DATE(state, action) {
        const min = new Date()
        min.setDate(min.getDate() + 2);
        state.minShippingDate = min.toLocaleDateString('th-TH')
    },

    CALCULATE_MAX_SHIPPING_DATE(state, action) {
        const max = new Date()
        max.setDate(max.getDate() + 4);
        state.maxShippingDate = max.toLocaleDateString('th-TH')
    },
    SAVE_URL(state, action) {
        console.log(action.payload)
        state.previousURL = action.payload
    },
    HIDE_CART_PANEL(state, action) {
        state.isShowPanel = false;
        state.cartPanelItem = [];
    },
    SEND_ITEM_TO_PANEL(state, action) {
        const tempProduct = { ...action.payload}
        state.isShowPanel = true;
        state.cartPanelItem = tempProduct
    },
    UPDATE_CART_DATA(state, action) {
        const tempIndex = state.cartItems.findIndex(
            item => item.id === action.payload.id,
        );
        state.cartItems[tempIndex].price = action.payload.price;
    },
    UPDATE_EXPRESS_CART_DATA(state, action) {
        const tempIndex = state.cartItems.findIndex(
            item => item.id === action.payload.id,
        );
        state.cartItems[tempIndex].express = action.payload.express;
        },
    UPDATE_FAV_DATA(state, action) {
    const tempIndex = state.favItem.findIndex(
        item => item.id === action.payload.id,
    );
    state.favItem[tempIndex].price = action.payload.price;
    },
    UPDATE_SFL_DATA(state, action) {
    const tempIndex = state.sflItem.findIndex(
        item => item.id === action.payload.id,
    );
    state.sflItem[tempIndex].price = action.payload.price;
    },
    UPDATE_CART_STOCK(state, action) {
    const tempIndex = state.cartItems.findIndex(
        item => item.id === action.payload.id,
    );
    state.cartItems[tempIndex].stock = action.payload.stock;
    },
    UPDATE_CART_STOCK_OPTIONAL(state, action) {
    const tempIndex = state.cartItems.findIndex(
        item => item.id === action.payload.id,
    );
    state.cartItems[tempIndex].stock = action.payload.optionalList.stock;
    },
    UPDATE_CART_DISCOUNT(state, action) {
        const tempIndex = state.cartItems.findIndex(
            item => item.id === action.payload.id,
        );
        state.cartItems[tempIndex].discount = action.payload.discount;
    },
    UPDATE_CART_NORMAL_PRICE(state, action) {
        const tempIndex = state.cartItems.findIndex(
            item => item.id === action.payload.id,
        );
        state.cartItems[tempIndex].normalPrice = action.payload.normalPrice;
    },
  }
});

export const {
    ADD_TO_CART, 
    DECREASE_CART, 
    REMOVE_FROM_CART, 
    CLEAR_CART, 
    CALCULATE_SUBTOTAL, 
    CALCULATE_TOTAL_QUANTITY, 
    SAVE_URL, 
    HIDE_CART_PANEL, 
    SEND_ITEM_TO_PANEL,
    CALCULATE_EXPRESS_DELIVERY_COST,
    CALCULATE_REGULAR_DELIVERY_COST,
    CALCULATE_SHIPPING_COST,
    CALCULATE_GRAND_TOTAL,
    CALCULATE_PICKUP_DATE,
    CALCULATE_MIN_SHIPPING_DATE,
    CALCULATE_MAX_SHIPPING_DATE,
    STORE_CARTITEM,
    STORE_FAVITEM,
    STORE_SFLITEM,
    ADD_TO_FAV,
    REMOVE_FROM_FAV,
    ADD_TO_SFL,
    REMOVE_FROM_SFL,
    UPDATE_CART_DATA,
    UPDATE_FAV_DATA,
    UPDATE_SFL_DATA,
    UPDATE_CART_STOCK,
    UPDATE_CART_STOCK_OPTIONAL,
    SAVE_DELIVERY_ID,
    UPDATE_EXPRESS_CART_DATA,
    UPDATE_CART_DISCOUNT,
    UPDATE_CART_NORMAL_PRICE
} = cartSlice.actions;

export const selectCartItems = (state) => state.cart.cartItems
export const selectCartTotalQuantity = (state) => state.cart.cartTotalQuantity
export const selectCartTotalAmount = (state) => state.cart.cartTotalAmount
export const selectPreviousURL = (state) => state.cart.previousURL
export const selectIsShowCartPanel = (state) => state.cart.isShowPanel
export const selectCartPanelItem = (state) => state.cart.cartPanelItem
export const selectRegularShipping = (state) => state.cart.regularShipping
export const selectShippingCost = (state) => state.cart.shippingCost
export const selectShippingMethod = (state) => state.cart.shippingMethod
export const selectGrandTotal = (state) => state.cart.grandTotal
export const selectPickupDate = (state) => state.cart.pickupDate
export const selectMinShippingDate = (state) => state.cart.minShippingDate
export const selectMaxShippingDate = (state) => state.cart.maxShippingDate
export const selectFavItem = state => state.cart.favItem;
export const selectSFLItem = state => state.cart.sflItem;
export const selectDeliveryId = state => state.cart.deliveryid

export default cartSlice.reducer