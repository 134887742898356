import React, { useEffect, useState } from 'react'
import styles from './MainReview.module.scss'
import useFetchCollection from '../../../customHook/useFetchCollection'
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import Loader from '../../loader/Loader'

const MainReview = () => {
    
    const [reviews, setReviews] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getCollection();
    }, []);

    const getCollection = async () => {
        setIsLoading(true);
        try {
            const docRef = collection(db, 'reviews');
    
            onSnapshot(docRef, async (snapshot) => {
                const promises = snapshot.docs.map(async (doc) => {
                    const reviewData = doc.data();
                    const name = await getProductName(reviewData.productID);
                    
                    return {
                        id: reviewData.productID,
                        name,
                        ...reviewData
                    };
                });
    
                const allData = await Promise.all(promises);
                setReviews(allData);
                setIsLoading(false);     
            });
        } catch (error) {
            setIsLoading(false);
            toast.error(error.message);
        }
    };

    // const savingReview = review => {
    //     try {
    //         addDoc(collection(db, "reviews"), review);
    //       } catch (error) {
    //         console.log(error);
    //       }
    // }


    const getProductName = async id => {
        const docRef = doc(db, 'products', id)
        const docSnap = await getDoc(docRef)
            
        if (docSnap.exists()) {
            const obj = {
            ...docSnap.data()
            }
            return obj.name;
        } else {
            return 'not found'
        }
    };



  return (
    <div className={styles.mainreview}>
        {isLoading && <Loader />}
            <h3>REVIEWS</h3>
          <div className={styles.content}>
            {reviews.length === 0 ? (
              <p>No review found</p>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Product Name</th>
                    <th>Rate</th>
                    <th>Description</th>
                    <th>User Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {reviews.map((order, index) => {
                    const {name, createdAt, rate, review, userName} = order;
                    const formattedDate = dayjs(createdAt.toDate()).format('DD/MM/YYYY HH:mm');
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{formattedDate}</td>
                        <td>{name}</td>
                        <td>{rate}</td>
                        <td>{review}</td>
                        <td>{userName}</td>
                        <td></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
        </div>
    </div>
  )
}

export default MainReview