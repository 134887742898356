import { doc, setDoc, Timestamp } from 'firebase/firestore'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { db } from '../../../firebase/config'
import Card from '../../card/Card'
import Loader from '../../loader/Loader'
import styles from "./ChangeOrderStatus.module.scss"
import { useTranslation } from 'react-i18next'

const ChangeOrderStatus = ({order, id}) => {
    const {t, i18n} = useTranslation();
    const [status, setStatus] = useState("");
    const [trackingProvider, setTrackingProvider] = useState('');
    const [trackingID, setTrackingID] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const sendNotification = () => {
        fetch('https://butternegg-backend.onrender.com/sendNotificationUser', {
        // fetch('http://localhost:4242/sendNotificationUser', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 
                      'Access-Control-Allow-Origin': '*',
                     },
            body: JSON.stringify({
            title: 'Order Status Changed',
            message: `Your order is now ${status.toLowerCase()}.`,
            message_th: `คำสั่งซื้อของท่าน${t(status, { lng: 'th' })}`,
            userIds: order?.userID
            }),
        })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          return res.json().then(json => Promise.reject(json));
        })
        .then( () => {
            toast.success("Send notification order status successfully")
        }).catch(error => toast.error(error.message))
    }

    const editOrder = async (e, id) => {
        e.preventDefault()
        setIsLoading(true)

        const orderConfig = {
            orderStatus: status,
            trackingProvider: trackingProvider,
            trackingID: trackingID,
            editedAt: Timestamp.now().toDate()
        }

        try {
            await setDoc(doc(db, "orders", id), orderConfig, {merge: true})
            sendNotification()
            setIsLoading(false)
            toast.success("Order status changes successfully")
            navigate("/admin/orders")
        } catch (error) {
            setIsLoading(false)
            toast.error(error.message)
        
        }

    }

  return (
    <>
        {isLoading && <Loader />}
        <div className={styles.status}>
            <Card cardClass={styles.card}>
                <h4>Update Status</h4>
                <form onSubmit={(e) => editOrder(e, id)}>
                    <span>
                        <select required value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="" disabled>-- Choose one --</option>
                            <option value="Pending">Pending</option>
                            <option value="Processing">Processing</option>
                            <option value="Shipped">Shipped</option>
                            <option value="Completed">Completed</option>
                            <option value="Ready">Ready to pick</option>
                            <option value="Canceled">Canceled</option>


                        </select>
                    </span>
                    {status === 'Shipped' ? (
                        <div className={styles.tracking}>
                        <h4>
                            Tracking Provider
                        </h4>
                        <input type="text" name="trackingprovider" value={trackingProvider} onChange={(e) => setTrackingProvider(e.target.value)} />
                        <h4>
                            Tracking ID
                        </h4>
                        <input type="text" name="trackingid" value={trackingID} onChange={(e) => setTrackingID(e.target.value)} />
                    </div>
                    ) : null}
                    
                   
                    <span>
                        <button type="submit" className='--btn --btn-primary'>Update Status</button>
                    </span>

                </form>

            </Card>
        </div>
    </>
  )
}

export default ChangeOrderStatus