import React, { useEffect } from 'react'
import styles from './StoreSettings.module.scss'
import useFetchCollection from '../../../customHook/useFetchCollection'
import { Divider, Switch } from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase/config';

const StoreSettings = () => {
    const {data, isLoading} = useFetchCollection('pickupaddress');

    const handleSwitch = async (e, id) => {
        try {
            await setDoc(doc(db, "pickupaddress", id), {isOpenDelivery: !e}, {merge: true})
        }
        catch (error) {
            console.log(error);
        }
    }

  return (
    <div className={styles.storesettings}>
        <p className={styles.maintitle}>STORE SETTINGS</p>
        <div className={styles.content}>
            {data.map((item, index) => {
                const {branch, lat, lng, email, shippingAddress, shippingAddressTH, isOpenDelivery, id} = item;
                return (
                    <div className={styles.storelist} key={index}>
                        <p className={styles.title}>{branch?.toUpperCase()}</p>
                        <div className={styles.switch}>
                            <Switch checked={isOpenDelivery} onChange={() => handleSwitch(isOpenDelivery, id)}/>
                            <p>DELIVERY</p>
                        </div>
                        <div className={styles.desc}>
                            <p>Lat {lat}</p>
                            <p>Lng {lng}</p>
                            <p>Email {email}</p>

                            <div className={styles.shippingcontainer}>
                                <p>SHIPPING ADDRESS</p>
                                <div className={styles.shippingaddress}>
                                    <div className={styles.shippingdetail}>
                                        <p>EN</p>
                                        <p>{shippingAddress.name} {shippingAddress.name2}</p>
                                        <p>{shippingAddress.line1}</p>
                                        <p>{shippingAddress.line2}</p>
                                        <p>{shippingAddress.city}</p>
                                        <p>{shippingAddress.state}</p>
                                        <p>{shippingAddress.country}</p>
                                        <p>{shippingAddress.postal_code}</p>
                                        <p>{shippingAddress.prefix}{shippingAddress.phone}</p>
                                    </div>
                                    <div className={styles.shippingdetail}>
                                        <p>TH</p>
                                        <p>{shippingAddressTH.name} {shippingAddressTH.name2}</p>
                                        <p>{shippingAddressTH.line1}</p>
                                        <p>{shippingAddressTH.line2}</p>
                                        <p>{shippingAddressTH.city}</p>
                                        <p>{shippingAddressTH.state}</p>
                                        <p>{shippingAddressTH.country}</p>
                                        <p>{shippingAddressTH.postal_code}</p>
                                        <p>{shippingAddressTH.prefix}{shippingAddressTH.phone}</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <Divider style={{paddingTop: 15, paddingBottom: 15}}/>
                    </div>
                )

            })}
        </div>
    </div>
  )
}

export default StoreSettings