import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    filteredProducts: [],
    category: '',
}

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {

    FILTER_BY_SEARCH(state, action) {
        const { products, search } = action.payload
        const tempProducts = products.filter((product) => 
            product?.name?.toLowerCase().includes(search.toLowerCase()) || 
            product?.name_th?.toLowerCase().includes(search.toLowerCase()) ||
            product?.category?.toLowerCase().includes(search.toLowerCase()))
        state.filteredProducts = tempProducts
    },

    SORT_PRODUCTS(state, action) {
        const { products, sort} = action.payload
        let tempProducts = []
        if (sort === "latest") {
            tempProducts = products
        }
        
        if (sort === "lowest-price") {
            tempProducts = products.slice().sort((a, b) => {
                return a.price - b.price
            })
        }

        if (sort === "highest-price") {
            tempProducts = products.slice().sort((a, b) => {
                return b.price - a.price
            })
        }

        if (sort === "a-z") {
            tempProducts = products.slice().sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
        }

        if (sort === "z-a") {
            tempProducts = products.slice().sort((a, b) => {
                return b.name.localeCompare(a.name)
            })
        }

        if (sort === "sku") {
            tempProducts = products.slice().sort((a, b) => {
                return a.sku - b.sku
            })
        }

        state.filteredProducts = tempProducts
    },

    FILTER_BY_CATEGORY(state, action) {
        const { products, category } = action.payload
        let tempProducts = []
        if (category === "All") {
            tempProducts = products 
        } else {
            tempProducts = products.filter((product) => product.category === category)
            state.category = category
        }
        state.filteredProducts = tempProducts
    },

    FILTER_BY_SUBCATEGORY(state, action) {
        const { products, subcategory, lng } = action.payload
        let tempProducts = []
        if (lng === 'en') {
            if (subcategory === "all") {
                tempProducts = products 
            } else {
                tempProducts = products.filter((product) => product.subcategory === subcategory)
            }
            state.filteredProducts = tempProducts
        } else if (lng === 'th') {
            if (subcategory === "all") {
                tempProducts = products 
            } else {
                tempProducts = products.filter((product) => product.subcategory_th === subcategory)
            }
            state.filteredProducts = tempProducts
        }

    },

    FILTER_BY_PRICE(state, action) {
        const { products, price } = action.payload
        let tempProducts = []
        tempProducts = products.filter((product) => product.price <= price)
        state.filteredProducts = tempProducts
    }
  }
});

export const { FILTER_BY_SEARCH, SORT_PRODUCTS, FILTER_BY_CATEGORY, FILTER_BY_SUBCATEGORY, FILTER_BY_PRICE } = filterSlice.actions

export const selectFilteredProduct = (state) => state.filter.filteredProducts
export const selectCategory = (state) => state.filter.category

export default filterSlice.reducer