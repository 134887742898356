import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useFetchCollection from '../../../customHook/useFetchCollection'
import { selectUserID } from '../../../redux/slice/authSlice'
import { selectOrderHistory, STORE_ORDERS } from '../../../redux/slice/orderSlice'
import Loader from '../../loader/Loader'
import styles from './Orders.module.scss'
import { Divider } from '@mui/material'
import useFetchCollectionOrdersQR from '../../../customHook/useFetchCollectionOrdersQR'


const Orders = () => {

  const { data, isLoading } = useFetchCollection("orders");
  const { ordersQR, isLoadingOrdersQR } = useFetchCollectionOrdersQR();
  const orders = useSelector(selectOrderHistory)
  const userID = useSelector(selectUserID)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  
  useEffect(() => {
    dispatch(STORE_ORDERS(data))
  },[dispatch, data])

  const handleClick = (id) => {
    navigate(`/admin/order-details/${id}`)
  }

  const handleClickQR = (id) => {
    navigate(`/admin/order-qr-details/${id}`)
  }

  return (
    <>
      <div className={styles.order}>
        <div className={styles.orderqr}>
          <h3>ORDER QR</h3>
          <div className={styles.content}>
            {ordersQR.length === 0 ? (
              <p>No order found</p>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Location</th>
                    <th>Total Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {ordersQR.map((order, index) => {
                    const {id, orderDate, orderTime, orderAmount, orderStatus} = order
                    const {name, name2, state} = order.shippingAddress
                    return (
                      <tr key={id} onClick={() => handleClickQR(id)}>
                        <td>{index + 1}</td>
                        <td>{orderDate} at {orderTime}</td>
                        <td>{name} {name2}</td>
                        <td>{state}</td>
                        <td>{orderAmount}{" THB"}</td>
                        <td>
                          <p className={orderStatus !== "Completed" ? `${styles.pending}` : `${styles.delivered}`}>
                            {orderStatus}
                          </p>
                        </td>
                        
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
          <Divider style={{width: '100%'}} />
        </div>
        <h3>ALL CUSTOMERS ORDER</h3>
        <p>Open an order to <b>change order status</b></p>
        <br />
        <>
          {isLoading && <Loader />}
          <div className={styles.table}>
            {orders.length === 0 ? (
              <p>No order found</p>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Location</th>
                    <th>Total Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, index) => {
                    const {id, orderDate, orderTime, orderAmount, orderStatus} = order
                    const {name, name2, state} = order.shippingAddress
                    return (
                      <tr key={id} onClick={() => handleClick(id)}>
                        <td>{index + 1}</td>
                        <td>{orderDate} at {orderTime}</td>
                        <td>{name} {name2}</td>
                        <td>{state}</td>
                        <td>{orderAmount}{" THB"}</td>
                        <td>
                          <p className={orderStatus !== "Completed" ? `${styles.pending}` : `${styles.delivered}`}>
                            {orderStatus}
                          </p>
                        </td>
                        
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}

          </div>
        </>

      </div>
    </>
  )

}

export default Orders