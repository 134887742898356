import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import styles from "./OrderDetails.module.scss"
import useFetchDocument from '../../../customHook/useFetchDocument'
import ChangeOrderStatus from '../changeOrderStatus/ChangeOrderStatus'
import { CircularProgress } from '@mui/material'

const OrderDetails = () => {
    const [order, setOrder] = useState(null)
    const {id} = useParams()

    const { document } = useFetchDocument("orders", id)

    useEffect(() => {
        setOrder(document)
    },[document])

    const handleCheckParcelStatus = () => {
        if (order?.trackingProvider === 'Inter Express') {
            window.open(`https://iel.co.th/tracking/?trackingNo=${order?.trackingID}`, "_blank");
        }
    }


  return (
    <>
        <div className={styles.table}>
            <h2>Order Details</h2>
            <div>
                <Link to="/admin/orders">
                    &larr; Back To Orders
                </Link>
            </div>
            <br />
            {order === null ? (
                <div className={styles.loader}>
                    <CircularProgress color="inherit" />
                </div> 
            ) : (
                <>
                    <p>
                        <b>Order ID</b> {order.id}
                    </p>
                    <p>
                        <b>Order Amount</b> {order.orderAmount} THB
                    </p>
                    <p>
                        <b>Order Status</b> {order.orderStatus}
                    </p>
                    <p>
                        <b>Payment Method</b> {order.paymentMethod}
                    </p>
                    <p>
                        <b>Shipping Method</b> {order.shippingMethod}
                    </p>
                    {order?.shippingMethod === 'express' ? (
                        <>
                        <p>
                            <b>Express Delivery ID</b> {order?.expressOrderId}
                        </p>
                        <p>
                            <b>Express Delivery Status</b> {order?.expressOrderStatus}
                        </p>
                        </>
                    ) : null}
                    {order?.shippingMethod === 'pickup' || order?.shippingMethod === 'expressPickup' ? (
                        <>
                        <p>
                            <b>Pickup Date</b> {order?.pickupDate}
                        </p>
                        <p>
                            <b>Pickup Time</b> {order?.pickupTime}
                        </p>
                        </>
                    ) : null}
                    {order?.shippingMethod === 'delivery'  && order?.orderStatus === 'Shipped' ? (
                        <div>
                            <p>
                                <b>Delivery Provider</b> {order?.trackingProvider}
                            </p>
                            <p>
                                <b>Tracking Number</b> {order?.trackingID}
                            </p>

                            <div className={styles.checkdiv}>
                                <button className={styles.checktracking} onClick={() => handleCheckParcelStatus()}>
                                    Check Parcel Status
                                </button>
                            </div>
                            
                        </div>
                    ) : null}
                    <p>
                        <b>E-mail</b> {order.userEmail}
                    </p>
                    <p>
                        <b>Shipping Address</b>
                        <br />
                        {order.shippingAddress.name} {order.shippingAddress.name2}
                        <br />
                        {order.shippingAddress.line1} {order.shippingAddress.line2} {order.shippingAddress.city}
                        <br />
                        {order.shippingAddress.state}
                        <br />
                        {order.shippingAddress.postal_code}
                        <br />
                        {order.shippingAddress.prefix}{order.shippingAddress.phone}

                    </p>
                    {order?.billingAddress ? (
                         <p>
                            <b>Billing Address</b>
                            <br />
                            {order.billingAddress.name} {order.billingAddress.name2}
                            <br />
                            {order.billingAddress.line1} {order.billingAddress.line2} {order.billingAddress.city}
                            <br />
                            {order.billingAddress.state}
                            <br />
                            {order.billingAddress.postal_code}
                            <br />
                            {order.billingAddress.prefix}{order.billingAddress.phone}
    
                        </p>
                    ) : null}
                   
                    <p>
                        <b>Note Message</b> {order.noteMsg}
                    </p>
                    <br />
                    {order.cartItems.map((cart,index) => {
                                const {id, name, price, imageURL, cartQuantity} = cart
                                return (
                                    <div key={id}>
                                        <p>
                                            {name.toUpperCase()} x {cartQuantity}
                                        </p>
                                    </div>
                                )
                    }
                    )}
                    <hr />
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {order.cartItems.map((cart,index) => {
                                const {id, name, price, imageURL, cartQuantity} = cart
                                return (
                                    <tr key={id}>
                                        <td>
                                            <b>{index + 1}</b>
                                        </td>
                                        <td>
                                            <img src={imageURL[0].imageURL} alt={name} style={{width:"100px"}} />
                                        </td>
                                        <td>
                                            <p>
                                                <b>{name.toUpperCase()}</b>
                                            </p>
                                            {`${price} THB`}
                                            <p>
                                                {`x ${cartQuantity}`}
                                            </p>
                                        </td>
                                        <td>{`${price * cartQuantity} THB`}</td>
                                        
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </>
            )}
            <ChangeOrderStatus order={order} id={id}/>
        </div>
    </>
  )
}

export default OrderDetails