import { collection, onSnapshot, orderBy, query } from "firebase/firestore"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { db } from "../firebase/config"

const useFetchCollectionBlogs = (collectionName) => {
    const [blogs, setBlogs] = useState([])
    const [isLoadingBlogs, setIsLoadingBlogs] = useState(false)

    const getCollection = () => {
        setIsLoadingBlogs(true)
        try{
          const docRef = collection(db, collectionName)
          const q = query(docRef, orderBy("createdAt","desc"))
          onSnapshot(q, (snapshot) => {
            const allData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data()
            }))
            setBlogs(allData)
            setIsLoadingBlogs(false)
            
          })
        } catch (error) {
            setIsLoadingBlogs(false)
          toast.error(error.message)
        }
      }

      useEffect(() => {
        getCollection()
      },[])

      return {blogs, isLoadingBlogs}

}

export default useFetchCollectionBlogs