import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    posts: [],
    blogs: [],
    orderNumber: '',
}

const utilSlice = createSlice({
  name: "util",
  initialState,
  reducers: {
    STORE_POSTS(state, action) {
        state.posts = action.payload.posts
    },
    STORE_BLOGS(state, action) {
      state.blogs = action.payload.blogs
    },
    STORE_ORDER_NUMBER(state, action) {
      state.orderNumber = action.payload
    }
  }
});

export const { STORE_POSTS, STORE_BLOGS, STORE_ORDER_NUMBER } = utilSlice.actions

export const selectPosts = (state) => state.util.posts
export const selectBlogs = (state) => state.util.blogs
export const selectOrderNumber = (state) => state.util.orderNumber

export default utilSlice.reducer

