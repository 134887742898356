import React from 'react'
import Card from '../card/Card'
import styles from "./InfoBox.module.scss"

const InfoBox = ({cardClass, title, count, icon}) => {
  return (
    <div className={styles["info-box"]}>
        <Card cardClass={cardClass}>
            <p className={styles.title}>{title}</p>
            <span>
                <p className={styles.count}>{count}</p>
                {icon}
            </span>

        </Card>
    </div>
  )
}

export default InfoBox