import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    products: [],
    minPrice: null,
    maxPrice: null,
    page: null,
}

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    STORE_PRODUCTS(state, action) {
        state.products = action.payload.products
    },
    GET_PRICE_RANGE(state, action) {
      const {products} = action.payload
      const array = []
      products.map((product) => {
        const price = product.price
        return array.push(price)
      })
      const max = Math.max(...array)
      const min = Math.min(...array)
      state.minPrice = min
      state.maxPrice = max
    },
    GET_STOCK(state,action) {
      const stock = state.products.stock
      console.log(stock)
    },
    STORE_PAGINATION_PAGE(state,action) {
      state.page = action.payload
    }
  }
});

export const { STORE_PRODUCTS,GET_PRICE_RANGE,STORE_PAGINATION_PAGE } = productSlice.actions

export const selectProducts = (state) => state.product.products

export const selectMinPrice = (state) => state.product.minPrice

export const selectMaxPrice = (state) => state.product.maxPrice

export const selectPaginationPage = (state) => state.product.page

export default productSlice.reducer

