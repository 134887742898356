import {React, useState} from 'react'
import styles from "./auth.module.scss"
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth, db } from '../../firebase/config'
import { toast } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import { useDispatch } from 'react-redux'
import { Alert, TextField } from '@mui/material'
import { SET_ACTIVE_USER } from '../../redux/slice/authSlice'
import { useTranslation } from 'react-i18next'
import { Timestamp, doc, getDoc, setDoc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'

const Login = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isNotCorrect, setIsNotCorrect] = useState(false)
    const [showErrorUserName, setShowErrorUserName ] = useState(false)
    const [showErrorPassword, setShowErrorPassword ] = useState(false)
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("")

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const redirectUser = () => {
        navigate("/admin")
    }


    const handleEmail = (e) => {
        const emailValidate = e.target.value.includes('@')
        if (!emailValidate){
            setShowErrorUserName(true)
        } else {
            setShowErrorUserName(false)
        }
        setIsNotCorrect(false)
        setEmail(e.target.value)
        
    }

    const handlePassword = (e) => {
        const passwordValidate = e.target.value
        if(passwordValidate.length < 6) {
            setShowErrorPassword(true)
            setPasswordErrorMessage(t('needmorepassword'))
        } else {
            setShowErrorPassword(false)
            
        }
        setIsNotCorrect(false)
        setPassword(e.target.value)
    }

    const getUserInfo = async (id, userEmail, userName) => {
        try {
        const docRef = doc(db, 'users', id)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            const obj = {
             id: id,
             ...docSnap.data()
            }
            dispatch(
                SET_ACTIVE_USER({
                  email: obj.userEmail,
                  userName: obj.userName,
                  userID: id,
                }),
              );
              toast.success(t('loginfinishedtoast'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsLoading(false)
            redirectUser()
           }
           else {
            console.log('User not found.');
            try {
                await setDoc(doc(db, "users", id), {
                    userId: id,
                    userEmail: userEmail,
                    userName: userName,
                    platform: 'website',
                    createdAt: Timestamp.now().toDate(),
                })
                .then(() => {
                    dispatch(
                    SET_ACTIVE_USER({
                        email: userEmail,
                        userName: userName,
                        userID: id,
                    }),
                    );
                    toast.success(t('loginfinishedtoast'), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setIsLoading(false)
                    redirectUser()
                });
            } catch (error) {
                console.log(error);
            }
            }
        } catch (error) {
          console.log(error);
        }
      };

     const loginUser = (e) => {
        e.preventDefault();
        if (email === '') {
            setShowErrorUserName(true)
        }
        if (password === '') {
            setShowErrorPassword(true)
            setPasswordErrorMessage(t('enterpassword'))
        }
        else if (!showErrorUserName && !showErrorPassword) {
            setIsLoading(true)
            signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const {email, uid} = userCredential.user;
                const userName = '@' + 'user-' + uid.slice(0, 10);
                getUserInfo(uid, email, userName)
            })
            .catch((error) => {
                setIsLoading(false)
                setIsNotCorrect(true)
                console.log(error)
            })
        }
        
    }
  return (
    <>
        {isLoading && <Loader />}
            <section className={`container ${styles.auth}`}>
                <div className={styles.form}>
                    <h2>{t('loginheader')}</h2>
                    {isNotCorrect ? ( 
                    <div className={styles.alert}>
                        <Alert severity="error">
                            <p>{t('usernotcorrect')}</p>
                        </Alert>
                    </div> 
                    ) : null}
                    
                    
                    <form onSubmit={loginUser} noValidate>
                        <div className={styles.emaildiv}>
                            <TextField
                                required
                                id="email"
                                name="email"
                                label={t('email')}
                                fullWidth
                                variant="standard"
                                value={email}
                                onChange={(e) => handleEmail(e)} 
                                error={showErrorUserName}
                                helperText={showErrorUserName ? t('enteremail') : null}
                            />
                        </div>
                        <TextField
                            required
                            id="password"
                            name="password"
                            type="password"
                            label={t("password")}
                            fullWidth
                            variant="standard"
                            value={password}
                            onChange={(e) => handlePassword(e)} 
                            error={showErrorPassword}
                            helperText={showErrorPassword ? passwordErrorMessage : null}
                        />
                        <div className={styles.submitbutton}>
                            <button type="submit">{t('login')}</button>
                        </div>
                    </form>
                    

                </div>

            </section>
    </>
  )
}

export default Login