import React, { useState } from 'react'
import styles from './NewPost.module.scss'
import Notiflix from 'notiflix';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { db, storage } from '../../../firebase/config';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Divider } from '@mui/material';
import Loader from '../../loader/Loader';
import { Timestamp, addDoc, collection, doc, setDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { selectPosts } from '../../../redux/slice/utilSlice';

const initialState = {
    title: "",
    caption: "",
    title_th: "",
    caption_th: "",
    imageURL: [],
    videoURL: [],
    itemOnPosts: [],
    type: "",
}



const NewPost = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const {id} = useParams()
    const posts = useSelector(selectPosts);
    const postsEdit = posts.find((item) => item.id === id)
  
    const [currentPost, setCurrentPost] = useState(() => {
      const newState = detechForm(id, { ...initialState}, postsEdit)
      return newState
    })
    const [uploadProgress, setUploadProgress] = useState(0)
    const [itemOnPostsInput, setItemOnPostsInput] = useState('');

    function detechForm(id, f1, f2) {
      if (id === "ADD"){
        return f1;
      }
      return f2
  
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target
        setCurrentPost({...currentPost, [name]: value})
    }

    const handleInputItemOnPosts = (e) => {
      setItemOnPostsInput(e.target.value);
  }

    const handleImageChange = (e) => {
        const file = e.target.files[0]
        const storageRef = ref(storage, `images/${Date.now()}${file.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file)
    
        uploadTask.on("state_changed", (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress)
        }, (error) => {
          toast.error(error.message)
        }, () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const prevState = currentPost.imageURL.slice()
            prevState.push({imageURL: downloadURL})
            const newObj = { ...currentPost, imageURL: prevState };
            setCurrentPost(newObj)
            toast.success("Image Uploaded Successfully.")
          })
        })
    }

    const handleVideoChange = (e) => {
        const file = e.target.files[0]
        const storageRef = ref(storage, `videos/${Date.now()}${file.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file)
    
        uploadTask.on("state_changed", (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress)
        }, (error) => {
          toast.error(error.message)
        }, () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const prevState = currentPost.videoURL.slice()
            prevState.push({videoURL: downloadURL})
            const newObj = { ...currentPost, videoURL: prevState };
            setCurrentPost(newObj)
            toast.success("Video Uploaded Successfully.")
          })
        })
    }

    const confirmDeleteImage = ({imageURL}) => {
        Notiflix.Confirm.show(
          'DELETE IMAGE',
          'Are you sure you want to delete this image?',
          'DELETE',
          'CANCEL',
          function okCb() {
            deleteImage({imageURL});
          },
          function cancelCb() {
            console.log("Delete Canceled")
          },
          {
            width: '450px',
            borderRadius: '1px',
            titleColor: 'black',
            okButtonBackground: 'darkred',
            cssAnimationStyle: 'zoom'
          }
    
        )
    }

    const confirmDeleteVideo = ({videoURL}) => {
        Notiflix.Confirm.show(
          'DELETE IMAGE',
          'Are you sure you want to delete this video?',
          'DELETE',
          'CANCEL',
          function okCb() {
            deleteVideo({videoURL});
          },
          function cancelCb() {
            console.log("Delete Canceled")
          },
          {
            width: '450px',
            borderRadius: '1px',
            titleColor: 'black',
            okButtonBackground: 'darkred',
            cssAnimationStyle: 'zoom'
          }
    
        )
    }

    const deleteImage = async ({imageURL}) => {

        const storageRef = ref(storage, imageURL)
        await deleteObject(storageRef)
        const prevState = currentPost.imageURL.slice()
        const findIndex = prevState.findIndex((item) => item.imageURL === imageURL)
        const newState = prevState.splice(0, findIndex)
        const newObj = { ...currentPost, imageURL: newState };
        setCurrentPost(newObj)
        toast.success("Deleted Image")
        
    }


    const deleteVideo = async ({videoURL}) => {
        const storageRef = ref(storage, videoURL)
        await deleteObject(storageRef)
        const prevState = currentPost.videoURL.slice()
        const findIndex = prevState.findIndex((item) => item.videoURL === videoURL)
        const newState = prevState.splice(0, findIndex)
        const newObj = { ...currentPost, videoURL: newState };
        setCurrentPost(newObj)
        toast.success("Deleted Video")
        
    }

    const addPost = (e) => {
        e.preventDefault()
        setIsLoading(true)
        const today = new Date();
        try{
            addDoc(collection(db, "posts"), {
                title: currentPost.title,
                caption: currentPost.caption,
                title_th: currentPost.title_th,
                caption_th: currentPost.caption_th,
                imageURL: currentPost.imageURL,
                videoURL: currentPost.videoURL,
                type: currentPost.type,
                createdDate: dayjs(today).format('DD/MM/YYYY'),
                createdTime: dayjs(today).format('HH:mm'),
                createdAt: Timestamp.now().toDate(),
                itemOnPosts: currentPost.itemOnPosts
        })
            setIsLoading(false)
            setUploadProgress(0)
            setCurrentPost({...initialState})
            toast.success("Post uploaded successfully.")
            navigate("/admin/postmanagement")
        } catch (error) {
            setIsLoading(false)
            toast.error(error.message)
        }
    }

    const editPost = (e) => {
      e.preventDefault()
      setIsLoading(true)
      try{
          setDoc(doc(db, "posts", id), {
              title: currentPost.title,
              caption: currentPost.caption,
              title_th: currentPost.title_th,
              caption_th: currentPost.caption_th,
              imageURL: currentPost.imageURL,
              videoURL: currentPost.videoURL,
              type: currentPost.type,
              createdDate: currentPost.createdDate,
              createdTime: currentPost.createdTime,
              createdAt: currentPost.createdAt,
              editedAt: Timestamp.now().toDate(),
              itemOnPosts: currentPost.itemOnPosts ? currentPost.itemOnPosts : []
      }, {merge: true})
          setIsLoading(false)
          setUploadProgress(0)
          setCurrentPost({...initialState})
          toast.success("Post uploaded successfully.")
          navigate("/admin/postmanagement")
      } catch (error) {
          setIsLoading(false)
          toast.error(error.message)
      }
  }

  const handleAddItemPosts = () => {
    const prevState = currentPost.itemOnPosts.slice()
    prevState.push({id: itemOnPostsInput})
    const newObj = { ...currentPost, itemOnPosts: prevState };
    setCurrentPost(newObj)
    setItemOnPostsInput('');
    toast.success("Item on posts Added Successfully.")
  }

  const handleDeleteItemPosts = (id) => {
    const prevState = currentPost.itemOnPosts.slice()
    const findIndex = prevState.findIndex((item) => item.id === id)
    const newState = prevState.splice(0, findIndex)
    const newObj = { ...currentPost, itemOnPosts: newState };
    setCurrentPost(newObj)
    toast.success("Item on posts Deleted Successfully.");
  }
    
  return (
    <>
    {isLoading && <Loader />}
    <div className={styles.newpost}>
        <h4>Create New Post</h4>
        <div className={styles.newpostinput}>
            <p className={styles.inputtitle}>
                Title
            </p>
            <p>EN</p>
            <input 
                type='text'
                name="title"
                value={currentPost.title}
                className={styles.textbox}
                onChange={e => handleInputChange(e)}
            />
            <p>TH</p>
            <input
                type='text'
                name="title_th"
                value={currentPost.title_th}
                className={styles.textbox}
                onChange={e => handleInputChange(e)}
            />
            <p className={styles.inputtitle}>
                Caption
            </p>
            <p>EN</p>
            <textarea 
                name="caption" 
                required
                value={currentPost.caption}
                onChange={e => handleInputChange(e)}
            />
            <p>TH</p>
            <textarea 
                name="caption_th" 
                required
                value={currentPost.caption_th}
                onChange={e => handleInputChange(e)}
            />
            <p className={styles.inputtitle}>Type</p>
            <div className={styles.radiotype}>
                <input 
                    type='radio' 
                    id='image' 
                    name='type'
                    checked={currentPost.type === 'image'}
                    value={'image'}
                    onChange={(e) => handleInputChange(e)} 
                />
                <p className={styles.text}>Image</p>
                <input 
                    type='radio' 
                    id='video' 
                    name='type'
                    value={'video'}
                    checked={currentPost.type === 'video'} 
                    onChange={(e) => handleInputChange(e)} 
                />
                <p className={styles.text}>Video</p>
            </div>
            <p className={styles.inputtitle}>
                Select File
            </p>
            {uploadProgress === 0 ? null :(
                <div className={styles.progress}>
                  <div className={styles["progress-bar"]} style={{width: `${uploadProgress}%`}}>
                    {uploadProgress < 100 ? `Uploading ${uploadProgress}%` : `Upload Complete ${uploadProgress}%`}
                  </div>
                </div>
            )}
            {currentPost.type === 'image' ? (
                <div>
                    <input type="file" accept="image/*" name="image" onChange={(e) => handleImageChange(e)} />

                    {currentPost.imageURL.map((image, index) => {
                    const {imageURL} = image;
                    return (
                        <div className={styles.productimage} key={index}>
                            <img src={imageURL} alt={index} style={{ width: "100px"}}/>
                            <div className={styles.deletebutton} >
                                <p onClick={() => confirmDeleteImage({imageURL})}>DELETE</p>
                            </div>
                        </div>
                    )
                    })}
                </div>
            ) : currentPost.type === 'video' ? (
                <div>
                    <input type="file" accept="video/*" name="video" onChange={(e) => handleVideoChange(e)} />

                    {currentPost.videoURL.map((video, index) => {
                    const {videoURL} = video;
                    return (
                        <div className={styles.productvideo} key={index}>
                            <p>{videoURL}</p>
                            <div style={{paddingTop: 20}} >
                                <p onClick={() => confirmDeleteVideo({videoURL})}>DELETE</p>
                            </div>
                        </div>
                    )
                    })}
                </div>
            ) : null}
            

            <div className={styles.itemonposts}>
              <Divider />
              <p className={styles.title}>ITEM ON POSTS</p>
              {currentPost.itemOnPosts?.map((item, index) => {
                const {id} = item;
                return (
                  <div key={index} className={styles.listitem}>
                    <p>{id}</p>
                    <div className={styles.button}>
                      <Button variant='contained' onClick={() => handleDeleteItemPosts(id)}>
                        DELETE
                      </Button>
                    </div>
                  </div>
                )
              })}
              <div className={styles.inputdiv}>
                <input
                  type='text'
                  name="itemonposts"
                  placeholder='Insert item id'
                  value={itemOnPostsInput}
                  className={styles.textbox}
                  onChange={e => handleInputItemOnPosts(e)}
                />
                <div className={styles.button}>
                  <Button variant='contained' onClick={() => handleAddItemPosts()}>
                    ADD
                  </Button>
                </div>
              </div>
            </div>

            <div className={styles.bottombutton}>
                <div className={styles.button}>
                    <Button variant='contained' onClick={detechForm(id, addPost, editPost)}>
                            OK
                    </Button>
                </div>
                <div className={styles.button}>
                    <Button variant='outlined' onClick={() => navigate('/admin/postmanagement')}>
                        Cancel
                    </Button>
                </div>
            </div>


            
        </div>
    </div>
    </>
  )
}

export default NewPost