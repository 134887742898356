import React from 'react'
import styles from "./Search.module.scss"
import { BiSearch } from "react-icons/bi"
import { useTranslation } from 'react-i18next'

const Search = ({value, onChange}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.search}>
        <BiSearch size={18} className={styles.icon} />
        <input type="text" placeholder={t('search')} value={value} onChange={onChange} />
    </div>
  )
}

export default Search