import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectPaginationPage } from '../../redux/slice/productSlice'
import styles from "./Pagination.module.scss"

const Pagination = ({currentPage, setCurrentPage, productsPerPage, totalProducts}) => {
    const { t } = useTranslation();
    const pageNumbers = []
    const totalPages = totalProducts / productsPerPage
    // Limit the page Numbers shown
    const [ pageNumberLimit, setPageNumberLimit ] = useState(5)
    const [ maxPageNumberLimit, setMaxPageNumberLimit ] = useState(5)
    const [ minPageNumberLimit, setMinPageNumberLimit ] = useState(0)

    const previousPage = useSelector(selectPaginationPage)

    useEffect(() => {
        if (previousPage > maxPageNumberLimit) {
            setMaxPageNumberLimit(previousPage + maxPageNumberLimit)
            setMinPageNumberLimit(previousPage + minPageNumberLimit - 1)
        }
    },[])


    // Paginate

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
        // Scroll up
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    // Go to next page

    const paginateNext = () => {
        setCurrentPage(currentPage + 1)
        // Show next set of pageNumbers
        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
            
        }
        // Scroll up
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    // Go to prev page
    const paginatePrev = () => {
        setCurrentPage(currentPage - 1)
         // Show prev set of pageNumbers
         if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
        }
        // Scroll up
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    const lastPage = Math.ceil(totalProducts / productsPerPage)

    const paginateFirst = () => {
        setCurrentPage(1)
        setMaxPageNumberLimit(5)
        setMinPageNumberLimit(0)
    }

    const paginateLast = () => {
        setCurrentPage(lastPage)
        setMaxPageNumberLimit(lastPage + pageNumberLimit - (lastPage%pageNumberLimit) )
        setMinPageNumberLimit(lastPage - (lastPage%pageNumberLimit))
        console.log(maxPageNumberLimit)
    }

    for (let i = 1; i <= lastPage; i++) {
        pageNumbers.push(i)
    }

    

  return (
    <ul className={styles.pagination}>
        <li onClick={paginatePrev} className={currentPage === pageNumbers[0] ? `${styles.hidden}` : null}><p>{t('prev')}</p></li>

        {
        minPageNumberLimit > 1 ? (
        <>
            <p className={styles.firstlastpage} onClick={paginateFirst}>{pageNumbers[0]}</p>
            <p className={styles.dot}>..</p>
        </> 
        ) : null }
        
        {pageNumbers.map((number) => {
            if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                <li key={number} 
                onClick={() => paginate(number)} 
                className={currentPage === number ? `${styles.active}` : null}>
                    {number}
                </li>
            )
            }
        })}

        {currentPage < lastPage ?
        maxPageNumberLimit !==  lastPage ?
        maxPageNumberLimit < lastPage ?
         (
        <>
            <p>..</p>
            <p  className={styles.firstlastpage} onClick={paginateLast}>{lastPage}</p>
        </> 
        ) : null : null : null}
        
        
        <li onClick={paginateNext} className={currentPage === pageNumbers[pageNumbers.length - 1] ? `${styles.hidden}` : null}><p>{t('next')}</p></li>


    </ul>
  )
}

export default Pagination