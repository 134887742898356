import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import AddProducts from '../../components/admin/addProducts/AddProducts'
import Home from '../../components/admin/home/Home'
import ViewProducts from '../../components/admin/viewProducts/ViewProducts'
import Navbar from '../../components/navbar/Navbar'
import styles from './Admin.module.scss'
import Orders from '../../components/admin/orders/Orders'
import OrderDetails from '../../components/admin/orderDetails/OrderDetails'
import Settings from '../../components/admin/settings/Settings'
import EditPolicy from '../../components/admin/EditPolicy/EditPolicy'
import StockControl from '../../components/admin/stockControl/StockControl'
import NewStockMng from '../../components/admin/newStockMng/NewStockMng'
import CreateNewStock from '../../components/admin/newStockMng/CreateNewStock'
import StoreSettings from '../../components/admin/storeSettings/StoreSettings'
import PostManagement from '../../components/admin/postManagement/PostManagement'
import NewPost from '../../components/admin/postManagement/NewPost'
import BlogManagement from '../../components/admin/blogManagement/BlogManagement'
import EditBlog from '../../components/admin/blogManagement/EditBlog'
import OrderQRDetails from '../../components/admin/orderQRDetails/OrderQRDetails'
import { IoMdMenu } from 'react-icons/io'
import MainReview from '../../components/admin/reviewManagement/MainReview'
import logo from '../../assets/logo-landscape-light.png'

const Admin = () => {
  const [hideMenu, setHideMenu] = useState(false);

  return (
    <div className={styles.admin}>
      <div className={styles.menuicon}>
        <div className={styles.togglemenuicon}>
          <IoMdMenu size={28} color="#000" onClick={() => setHideMenu(!hideMenu)}/>
        </div>
        <div className={styles.title}>
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className={styles.main}>
        <div className={hideMenu ? styles.navbarhide : styles.navbar}>
          <Navbar />
        </div>
        <div className={styles.content}>
          <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="all-products" element={<ViewProducts />}/>
            <Route path="add-product/:id" element={<AddProducts />}/>
            <Route path="orders" element={<Orders />}/>
            <Route path="order-details/:id" element={<OrderDetails />}/>
            <Route path="order-qr-details/:id" element={<OrderQRDetails />}/>
            <Route path="settings" element={<Settings />}/>
            <Route path="editpolicy" element={<EditPolicy />} />
            <Route path="stockcontrol" element={<StockControl />} />
            <Route path="newstockmng" element={<NewStockMng />} />
            <Route path="newstockmng/createnewstock" element={<CreateNewStock />} />
            <Route path="storesettings" element={<StoreSettings />} />
            <Route path="postmanagement" element={<PostManagement />} />
            <Route path="postmanagement/newpost/:id" element={<NewPost/>} />
            <Route path="blogmanagement" element={<BlogManagement />} />
            <Route path="blogmanagement/editblog/:id" element={<EditBlog/>} />
            <Route path="reviewmanagement" element={<MainReview />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default Admin