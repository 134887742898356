import { doc, setDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useFetchCollectionSettings from '../../../customHook/useFetchCollectionSettings'
import { db } from '../../../firebase/config'
import styles from './EditPolicy.module.scss'

const EditPolicy = () => {
    const {data, isLoading} = useFetchCollectionSettings()
    const policy = data.find(data => data.id ==='policy')
    const [privacyPolicyEN, setPrivacyPolicyEN] = useState('');
    const [privacyPolicyTH, setPrivacyPolicyTH] = useState('');
    const [retrnPolicyEN, setRetrnPolicyEN] = useState('');
    const [retrnPolicyTH, setRetrnPolicyTH] = useState('');
    const [cookiesPolicyEN, setCookiesPolicyEN] = useState('');
    const [cookiesPolicyTH, setCookiesPolicyTH] = useState('');
    const [paymentMethodEN, setPaymentMethodEN] = useState('');
    const [paymentMethodTH, setPaymentMethodTH] = useState('');
    const [shippingMethodEN, setShippingMethodEN] = useState('');
    const [shippingMethodTH, setShippingMethodTH] = useState('');
    const [deliveryStatusEN, setDeliveryStatusEN] = useState('');
    const [deliveryStatusTH, setDeliveryStatusTH] = useState('');

    const navigate = useNavigate();
    
    useEffect(() => {
        setPrivacyPolicyEN(policy?.privacyEN)
        setPrivacyPolicyTH(policy?.privacyTH)
        setRetrnPolicyEN(policy?.retrnPolicyEN)
        setRetrnPolicyTH(policy?.retrnPolicyTH)
        setCookiesPolicyEN(policy?.cookiesPolicyEN)
        setCookiesPolicyTH(policy?.cookiesPolicyTH)
        setPaymentMethodEN(policy?.paymentMethodEN)
        setPaymentMethodTH(policy?.paymentMethodTH)
        setShippingMethodEN(policy?.shippingMethodEN)
        setShippingMethodTH(policy?.shippingMethodTH)
        setDeliveryStatusEN(policy?.deliveryStatusEN)
        setDeliveryStatusTH(policy?.deliveryStatusTH)
    }, [data])

    const handleSubmit = () => {
        try{
            setDoc(doc(db, "settings", 'policy'),{
              privacyEN: privacyPolicyEN,
              privacyTH: privacyPolicyTH,
              retrnPolicyEN: retrnPolicyEN,
              retrnPolicyTH: retrnPolicyTH,
              cookiesPolicyEN: cookiesPolicyEN,
              cookiesPolicyTH: cookiesPolicyTH,
              paymentMethodEN: paymentMethodEN,
              paymentMethodTH: paymentMethodTH,
              shippingMethodEN: shippingMethodEN,
              shippingMethodTH: shippingMethodTH,
              deliveryStatusEN: deliveryStatusEN,
              deliveryStatusTH: deliveryStatusTH,
            })
            navigate('/admin/home')
          } catch (error) {
            console.log(error)
          }
    }
  return (
    <div className={styles.editpolicy}>
        <h3>Edit Policy</h3>
        <h4>Privacy Policy</h4>
        <p>EN</p>
        <textarea onChange={e => setPrivacyPolicyEN(e.target.value)} value={privacyPolicyEN} cols='100' rows='30'></textarea>
        <p>TH</p>
        <textarea onChange={e => setPrivacyPolicyTH(e.target.value)} value={privacyPolicyTH} cols='100' rows='30'></textarea>    
        <h4>Return Policy</h4>
        <p>EN</p>
        <textarea onChange={e => setRetrnPolicyEN(e.target.value)} value={retrnPolicyEN} cols='100' rows='30'></textarea>
        <p>TH</p>
        <textarea onChange={e => setRetrnPolicyTH(e.target.value)} value={retrnPolicyTH} cols='100' rows='30'></textarea>
        <h4>Payment Method</h4>
        <p>EN</p>
        <textarea onChange={e => setPaymentMethodEN(e.target.value)} value={paymentMethodEN} cols='100' rows='30'></textarea>
        <p>TH</p>
        <textarea onChange={e => setPaymentMethodTH(e.target.value)} value={paymentMethodTH} cols='100' rows='30'></textarea>
        <h4>Shipping Method</h4>
        <p>EN</p>
        <textarea onChange={e => setShippingMethodEN(e.target.value)} value={shippingMethodEN} cols='100' rows='30'></textarea>
        <p>TH</p>
        <textarea onChange={e => setShippingMethodTH(e.target.value)} value={shippingMethodTH} cols='100' rows='30'></textarea>
        <h4>Delivery Status</h4>
        <p>EN</p>
        <textarea onChange={e => setDeliveryStatusEN(e.target.value)} value={deliveryStatusEN} cols='100' rows='30'></textarea>
        <p>TH</p>
        <textarea onChange={e => setDeliveryStatusTH(e.target.value)} value={deliveryStatusTH} cols='100' rows='30'></textarea>
        <h4>Cookies Policy</h4>
        <p>EN</p>
        <textarea onChange={e => setCookiesPolicyEN(e.target.value)} value={cookiesPolicyEN} cols='100' rows='30'></textarea>
        <p>TH</p>
        <textarea onChange={e => setCookiesPolicyTH(e.target.value)} value={cookiesPolicyTH} cols='100' rows='30'></textarea>
        <div className={styles.adddiv}>
           <span className={styles.addbutton} onClick={() => handleSubmit()}>ADD</span>
        </div>
    </div>
  )
}

export default EditPolicy