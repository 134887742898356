import React, { useEffect, useState } from 'react'
import styles from './PostManagement.module.scss'
import { Box, Button, Modal } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import useFetchCollectionPosts from '../../../customHook/useFetchCollectionPosts';
import Notiflix from 'notiflix';
import { FaEdit, FaTrashAlt } from "react-icons/fa"
import { useDispatch, useSelector } from 'react-redux';
import { STORE_POSTS, selectPosts } from '../../../redux/slice/utilSlice';
import { deleteDoc, doc } from 'firebase/firestore';
import { db, storage } from '../../../firebase/config';
import { deleteObject, ref } from 'firebase/storage';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

const PostManagement = () => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [hideDetail, setHideDetail] = useState(null);
    const {posts, isLoadingPosts} = useFetchCollectionPosts('posts');
    const allPosts = useSelector(selectPosts);

    useEffect(() => {
      dispatch(STORE_POSTS({posts: posts}));
    },[dispatch, posts])


    const showDetail = id => {
        if (hideDetail) {
          setHideDetail(null)
        } else {
          setHideDetail(id)
        }
    }

    const editPost = id => {
      navigate(`/admin/postmanagement/newpost/${id}`)
    }

    const confirmDelete = (id, videoURL) => {
      Notiflix.Confirm.show(
        'Delete post.',
        'Are you sure to delete this post?',
        'Delete',
        'Cancel',
        function okCb() {
          deletePost(id, videoURL)
        },
        function cancelCb() {
          console.log("Delete Canceled")
        },
        {
          width: '320px',
          borderRadius: '8px',
          titleColor: 'orangered',
          okButtonBackground: 'orangered',
          cssAnimationStyle: 'zoom'
        }
  
      )
  
    }

    const deletePost = async (id, videoURL) => {
      try{
        await deleteDoc(doc(db, "posts", id))
        for (let i=0; i < videoURL.length; i++) {
          const array = videoURL[i]
          const storageRef = ref(storage, array.imageURL)
          await deleteObject(storageRef)
        }
        toast.success("Post deleted successfully.")
      } catch (error) {
        toast.error(error.message)
      }
    }
    
  return (
    <div className={styles.postmanagement}>
        <h4 style={{paddingBottom: 10}}>Post Management</h4>
        <Button variant='contained' onClick={() => navigate('newpost/ADD')}>
            Create new post
        </Button>
        <div className={styles.content}>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Title</th>
                <th>Caption</th>
                <th>Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {allPosts.map((item, index) => {
                const {title, title_th, caption, caption_th, type, id, createdDate, createdTime, videoURL} = item;
                return (
                  <tr key={id} onClick={() => showDetail(id)}>
                    <td>{index + 1}</td>
                    <td>{createdDate} {createdTime}</td>
                    <td>
                      <p>{title}</p>
                      <p>{title_th}</p>
                    </td>
                    <td>
                      <p>{caption}</p>
                      <p>{caption_th}</p>
                    </td>
                    <td>
                      {type}
                    </td>
                    <td className={styles.icons}>
                    <span onClick={() => editPost(id)}>
                      <FaEdit size={20} color="black" />
                    </span>
                    &nbsp;
                    <FaTrashAlt size={20} color="red" onClick={() => confirmDelete(id, videoURL)} />

                  </td>
                    
                  </tr>

                )
              })}
            </tbody>
          </table>
        </div>
        
    </div>
  )
}

export default PostManagement