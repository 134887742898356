import React, { useEffect, useState } from 'react'
import styles from './StockControl.module.scss'
import useFetchCollection from '../../../customHook/useFetchCollection'
import { useDispatch, useSelector } from 'react-redux'
import { STORE_PAGINATION_PAGE, STORE_PRODUCTS, selectPaginationPage, selectProducts } from '../../../redux/slice/productSlice'
import { FILTER_BY_CATEGORY, FILTER_BY_SEARCH, SORT_PRODUCTS, selectCategory, selectFilteredProduct } from '../../../redux/slice/filterSlice'
import { useNavigate } from 'react-router-dom'
import Notiflix from 'notiflix'
import { toast } from 'react-toastify'
import Loader from '../../loader/Loader'
import Search from '../../search/Search'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import Pagination from '../../pagination/Pagination'
import { deleteObject, ref } from 'firebase/storage'
import { deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore'
import { db, storage } from '../../../firebase/config'
import { Box, Button, Divider, Modal, Typography } from '@mui/material'

const StockControl = () => {
    const [search, setSearch] = useState("")
    const [category, setCategory] = useState("All")
    const [sort, setSort] = useState("latest")
    const [selectedBranch, setSelectedBranch] = useState('kallapaprueak');
    const [editStock, setEditStock] = useState(null);
    const [inputQty, setInputQty] = useState(0);
    const { data, isLoading } = useFetchCollection("products")
    const products = useSelector(selectProducts)
    const filteredProducts = useSelector(selectFilteredProduct)
    const navigate = useNavigate();
  
    // Pagination states
    const [ currentPage, setCurrentPage ] = useState(1)
    const [ productsPerPage, setProductsPerPage ] = useState(20)
    
    // Get Current Products
    const indexOfLastProduct = currentPage * productsPerPage
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct)
  
    const previousPage = useSelector(selectPaginationPage)
    const savedCategory = useSelector(selectCategory)
  
    const dispatch = useDispatch()
  
    const allCategory = [
      "All",
      ...new Set(products.map((product) => product.category))
    ]
  
    useEffect(() => {
      if (previousPage !== null) {
        setCurrentPage(previousPage)
        setCategory(savedCategory)
      } 
      else {
        setCurrentPage (1)
      }
    },[filteredProducts])
  
    useEffect(() => {
      dispatch(
                STORE_PRODUCTS({
                  products: data
                })
                )
    },[dispatch, data])
  
    useEffect(() => {
      dispatch(SORT_PRODUCTS({products: products, sort}))
    },[dispatch, products, sort])
  
    useEffect(() => {
      dispatch(FILTER_BY_SEARCH({ products, search}))
  
    },[dispatch, products, search])
  
    useEffect(() => {
      dispatch(FILTER_BY_CATEGORY({products: products, category: category}))
    },[dispatch, products, category])

    const activateEdit = (id) => {
      setEditStock(id);
      const updateData = [...filteredProducts];
      const selectedData = updateData.find((item) => item.id === id)

      if (selectedData?.stockControl) {
        const tempData = selectedData.stockControl.find((item) => item.branch === selectedBranch)
        if (!tempData) {
          setInputQty(0)
        } else {
          setInputQty(tempData.qty)
        }
        
      } else {
        setInputQty(0)
      }
    }

    const saveEditedStock = (id) => {
      setEditStock(null);
      try {
        const updateData = [...filteredProducts];
        const selectedData = updateData.find((item) => item.id === id)
        
        if (selectedData?.stockControl) {
          const tempIndex = selectedData.stockControl.findIndex((item) => item.branch === selectedBranch)
          if (tempIndex === -1) {
            const newState = {branch: selectedBranch, qty: Number(inputQty)}
            const updateNewData = [...selectedData.stockControl]
            updateNewData.push(newState);

            const qtyRef = doc(db, 'products', id);
            setDoc(qtyRef, { stockControl: updateNewData }, { merge: true });
          } else {
            const newState = {...selectedData.stockControl[tempIndex], qty: Number(inputQty)}
            const updateNewData = [...selectedData.stockControl]
            updateNewData.splice(tempIndex, 1, newState);

            const qtyRef = doc(db, 'products', id);
            setDoc(qtyRef, { stockControl: updateNewData }, { merge: true });
          }
          
        } else {
          const tempStockData = [];
          tempStockData.push({branch: selectedBranch, qty: inputQty});
          const qtyRef = doc(db, 'products', id);
          setDoc(qtyRef, { stockControl: tempStockData }, { merge: true });
        }

      } catch {
        console.log('Error Saving.')
      }
    }

    const handleQtyChange = (e, id) => {
      setInputQty(e.target.value);
    };

    const increaseOptionalQty = (id, name) => {
      try {
        const updateData = [...filteredProducts];
        const selectedData = updateData.find((item) => item.id === id)
        
        if (selectedData?.optionalList) {
          const tempIndex = selectedData.optionalList.findIndex((item) => item.name === name)
          if (tempIndex >= 0) {
            // Create a copy of the optionalList directly
            const newerData = [...selectedData.optionalList];
            if (newerData[tempIndex].branch) {
              const targetBranch = newerData[tempIndex].branch;
              const findBranchIndex = targetBranch.findIndex(item => item.name === selectedBranch);
              const updatedBranch = { ...targetBranch[findBranchIndex], qty: Number(targetBranch[findBranchIndex].qty) + 1 };
              const targetBranchs = [...targetBranch]
              targetBranchs.splice(findBranchIndex, 1, updatedBranch);
              const finalData = {...newerData[tempIndex], branch: targetBranchs}
              newerData.splice(tempIndex, 1, finalData)
              const qtyRef = doc(db, 'products', id);
              setDoc(qtyRef, { optionalList: newerData }, { merge: true });
            } else {
              const tempBranchData = [];
              tempBranchData.push({name: selectedBranch, qty: 0})
              const finalData = {...newerData[tempIndex], branch: tempBranchData}
              newerData.splice(tempIndex, 1, finalData)
              const qtyRef = doc(db, 'products', id);
              setDoc(qtyRef, { optionalList: newerData }, { merge: true });
            }
          } else {
            console.log('error');
          }
        }
      } catch (error) {
        console.log(error)
      }
    };

    const decreaseOptionalQty = (id, name) => {
      try {
        const updateData = [...filteredProducts];
        const selectedData = updateData.find((item) => item.id === id)
        
        if (selectedData?.optionalList) {
          const tempIndex = selectedData.optionalList.findIndex((item) => item.name === name)
          if (tempIndex >= 0) {
            // Create a copy of the optionalList directly
            const newerData = [...selectedData.optionalList];
            if (newerData[tempIndex].branch) {
              const targetBranch = newerData[tempIndex].branch;
              const findBranchIndex = targetBranch.findIndex(item => item.name === selectedBranch);
              const updatedBranch = { ...targetBranch[findBranchIndex], qty: Number(targetBranch[findBranchIndex].qty) - 1 };
              const targetBranchs = [...targetBranch]
              targetBranchs.splice(findBranchIndex, 1, updatedBranch);
              const finalData = {...newerData[tempIndex], branch: targetBranchs}
              newerData.splice(tempIndex, 1, finalData)
              const qtyRef = doc(db, 'products', id);
              setDoc(qtyRef, { optionalList: newerData }, { merge: true });
            }
          } else {
            console.log('error');
          }
        }
      } catch (error) {
        console.log(error)
      }
    };

    return (
      <>
      {isLoading && <Loader />}
      <div className={styles.table}>
        <h4>Stock Control</h4>
        <div className={styles.leftdiv}>
          <div className={styles.branchselect}>
              <select value={selectedBranch} onChange={(e) => setSelectedBranch(e.target.value)}>
                <option value="kallapaprueak">Kallapaprueak Branch</option>
              </select>
          </div>
          <div className={styles.availablestock}>
            <p className={styles.title}>Available Stock</p>
            <div className={styles.row}>
              <div className={styles.categorycontainer}>
                <p className={styles.titlecategory}>BAKE</p>
                {filteredProducts.map((item, index) => {
                  const {name, stockControl, category} = item;
                  const findBranchIndex = stockControl?.findIndex(item => item.branch === selectedBranch)
                  return (
                    stockControl[findBranchIndex]?.qty > 0 && category === 'BAKE' ? (
                      <p key={index}>{name.toUpperCase()} x {stockControl[findBranchIndex]?.qty}</p>
                    ) : null
                  )
                })}
              </div>
              <div className={styles.categorycontainer}>
                <p className={styles.titlecategory}>SNACKS</p>
                {filteredProducts.map((item, index) => {
                  const {name, stockControl, category} = item;
                  const findBranchIndex = stockControl?.findIndex(item => item.branch === selectedBranch)
                  return (
                    stockControl[findBranchIndex]?.qty > 0 && category === 'SNACKS' ? (
                      <p key={index}>{name.toUpperCase()} x {stockControl[findBranchIndex]?.qty}</p>
                    ) : null
                  )
                })}
              </div>
              <div className={styles.categorycontainer}>
                <p className={styles.titlecategory}>FROZEN</p>
                {filteredProducts.map((item, index) => {
                  const {name, stockControl, category} = item;
                  const findBranchIndex = stockControl?.findIndex(item => item.branch === selectedBranch)
                  return (
                    stockControl[findBranchIndex]?.qty > 0 && category === 'FROZEN' ? (
                      <p key={index}>{name.toUpperCase()} x {stockControl[findBranchIndex]?.qty}</p>
                    ) : null
                  )
                })}
              </div>
              <div className={styles.categorycontainer}>
                <p className={styles.titlecategory}>DRINKS</p>
                {filteredProducts.map((item, index) => {
                  const {name, stockControl, category} = item;
                  const findBranchIndex = stockControl?.findIndex(item => item.branch === selectedBranch)
                  return (
                    stockControl[findBranchIndex]?.qty > 0 && category === 'DRINKS' ? (
                      <p key={index}>{name.toUpperCase()} x {stockControl[findBranchIndex]?.qty}</p>
                    ) : null
                  )
                })}
              </div>
            </div>
          </div>
          <div className={styles.search}>
              <Search value={search} onChange={(e) => setSearch(e.target.value)}/>
          </div>
          
          <div className={styles.filter}>
            <div className={styles.sortcategory}>
                <select value={category} onChange={(e) => setCategory(e.target.value)}>
                    {allCategory.map((category, index) => {
                    return (
                        <option key={index} value={category}>{category.toUpperCase()}</option>
                    )
                    })}
                    
                </select>
            </div>
              
            <div className={styles.sort}>
                <select value={sort} onChange={(e) => setSort(e.target.value)}>
                    <option value="latest">LATEST</option>
                    <option value="lowest-price">LOWEST PRICE</option>
                    <option value="highest-price">HIGHEST PRICE</option>
                    <option value="a-z">A - Z</option>
                    <option value="z-a">Z - A</option>
                    <option value="sku">SKU</option>
                </select>
            </div>
          </div>
          
          <div className={styles.filterdesc}>
              <p>
                  <b>{filteredProducts.length}</b> products found
              </p>
          </div>
        </div>

  

        {filteredProducts.length === 0 ? (
          <p>No product found.</p>
        ) : (
          <table>
            <tbody>
              {currentProducts.map((product, index) => {
                const {id, name, name_th, price, imageURL, stock, express, stockControl, optional, optionalList} = product;
                return (
                  <tr key={id}>
                    <td>
                      <img src={imageURL[0].imageURL} alt={name} style={{width: "100px", height: "100px", objectFit: 'cover'}}/>
                    </td>
                    <td>
                      <p><b>{name}</b></p>
                      <p>{name_th}</p>
                      <p style={{paddingTop: 20}}>{`${price} THB`}</p>
                    </td>
                    <td>
                        <p style={{fontWeight: '500', color: 'black', paddingBottom: 10}}>Main Stock</p>
                        
                        {stockControl ? (
                          stockControl?.map((item, index) => {
                            const {branch, qty} = item
                            return (
                              branch === selectedBranch ? (
                                editStock === id ? (
                                  <input type='number' value={inputQty} key={index} onChange={(e) => handleQtyChange(e, id)}/>
                                ) : qty
                              ) : null
                            )
                          })
                        ) : (
                          editStock === id ? (
                            <input type='number' value={inputQty} key={index} onChange={(e) => handleQtyChange(e, id)}/>
                          ) : null
                        )}
                        {optional === true ? (
                          optionalList.map(item => {
                            const {name} = item;
                            const findBranchIndex = item.branch?.findIndex(item => item.name === selectedBranch);
                            if (findBranchIndex !== -1) { // Check if branch is found
                              const selectedBranchData = item.branch?.[findBranchIndex];
                              return (
                                <div style={{paddingTop: 10}}>
                                  <p style={{fontWeight: '500'}}>{name}</p>
                                  <div className={styles.row}>
                                    <p onClick={() => decreaseOptionalQty(id, name)}>-</p>
                                    <p style={{paddingLeft: 10, paddingRight: 10, fontWeight: '500'}}>{selectedBranchData?.qty}</p>
                                    <p onClick={() => increaseOptionalQty(id, name)}>+</p>
                                  </div>
                                </div>
                              );
                            } else {
                              // Handle case where branch is not found
                              return null; // Or display a message or placeholder
                            }
                          })
                        ) : null}
                    </td>
                    <td className={styles.icons}>
                      <span onClick={editStock === id ? () => saveEditedStock(id) : () => activateEdit(id)}>
                        <FaEdit size={20} color="black" />
                      </span>
                    </td>
                  </tr>
              )
            })}
            </tbody>
          </table>
        )}
        <Pagination 
          currentPage={currentPage} 
          setCurrentPage={setCurrentPage}
          productsPerPage={productsPerPage} 
          totalProducts={filteredProducts.length}
        />
  
      </div>
      
      </>
    )
}

export default StockControl