import { doc, setDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import Notiflix from 'notiflix';
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useFetchCollectionSettings from '../../../customHook/useFetchCollectionSettings';
import { db, storage } from '../../../firebase/config';
import styles from './Settings.module.scss'

const Settings = () => {

  const {data, isLoading} = useFetchCollectionSettings()
  const webImage = data.find(data => data.id ==='coverWebImage')
  const appImage = data.find(data => data.id ==='coverAppImage')
  const adImage = data.find(data => data.id ==='welcomeAdImage')
  const shippingMethod = data.find(data => data.id ==='shippingMethod')
  const allCategory = data.find(data => data.id ==='category')
  const [category, setCategory] = useState(null);
  const [inputSubCategory, setInputSubCategory] = useState('');
  const [inputSubCategoryTH, setInputSubCategoryTH] = useState('');
  const [inputCategory, setInputCategory] = useState('');
  const [coverWebImage, setCoverWebImage] = useState(null)
  const [coverAppImage, setCoverAppImage] = useState(null)
  const [welcomeAdImage, setWelcomeAdImage] = useState(null)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadProgressApp, setUploadProgressApp] = useState(0)
  const [loading, setLoading] = useState(false);
  const [express, setExpress] = useState(null);
  const [expressPickup, setExpressPickup] = useState(null);
  const [pickup, setPickup] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setCoverWebImage(webImage)
    setCoverAppImage(appImage)
    setWelcomeAdImage(adImage)
    setCategory(allCategory)
    setExpress(shippingMethod?.express)
    setExpressPickup(shippingMethod?.expressPickup)
    setPickup(shippingMethod?.pickup)
  },[data])

  const handleWebImageChange = e => {
    const file = e.target.files[0]
    const storageRef = ref(storage, `coverImages/${Date.now()}${file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on("state_changed", (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setUploadProgress(progress)
    }, (error) => {
      toast.error(error.message)
    }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        const prevState = coverWebImage.imageURL.slice()
        prevState.push({imageURL: downloadURL})
        const newObj = { ...coverWebImage, imageURL: prevState };
        setCoverWebImage(newObj)
        toast.success("Image Uploaded Successfully.")
      })
    })
  }

  const confirmDeleteWebImage = ({imageURL}) => {
    Notiflix.Confirm.show(
      'DELETE IMAGE',
      'Are you sure you want to delete this image?',
      'DELETE',
      'CANCEL',
      function okCb() {
        deleteWebImage({imageURL});
      },
      function cancelCb() {
        console.log("Delete Canceled")
      },
      {
        width: '450px',
        borderRadius: '1px',
        titleColor: 'black',
        okButtonBackground: 'darkred',
        cssAnimationStyle: 'zoom'
      }

    )
  }
  const deleteWebImage = async ({imageURL}) => {
    const storageRef = ref(storage, imageURL)
    await deleteObject(storageRef)
    const prevState = coverWebImage.imageURL.slice()
    const findIndex = prevState.findIndex((item) => item.imageURL === imageURL)
    const newState = prevState.splice(0,findIndex)
    const newObj = { ...coverWebImage, imageURL: newState };
    setCoverWebImage(newObj)
    toast.success("Deleted Image")
    
  }

  const handleAppImageChange = e => {
    const file = e.target.files[0]
    const storageRef = ref(storage, `coverImages/${Date.now()}${file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on("state_changed", (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setUploadProgressApp(progress)
    }, (error) => {
      toast.error(error.message)
    }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        const prevState = coverAppImage.imageURL.slice()
        prevState.push({imageURL: downloadURL})
        const newObj = { ...coverAppImage, imageURL: prevState };
        setCoverAppImage(newObj)
        toast.success("Image Uploaded Successfully.")
      })
    })
  }

  const confirmDeleteAppImage = ({imageURL}) => {
    Notiflix.Confirm.show(
      'DELETE IMAGE',
      'Are you sure you want to delete this image?',
      'DELETE',
      'CANCEL',
      function okCb() {
        deleteAppImage({imageURL});
      },
      function cancelCb() {
        console.log("Delete Canceled")
      },
      {
        width: '450px',
        borderRadius: '1px',
        titleColor: 'black',
        okButtonBackground: 'darkred',
        cssAnimationStyle: 'zoom'
      }

    )
  }
  const deleteAppImage = async ({imageURL}) => {
    const storageRef = ref(storage, imageURL)
    await deleteObject(storageRef)
    const prevState = coverAppImage.imageURL.slice()
    const findIndex = prevState.findIndex((item) => item.imageURL === imageURL)
    const newState = prevState.splice(0,findIndex)
    const newObj = { ...coverAppImage, imageURL: newState };
    setCoverAppImage(newObj)
    toast.success("Deleted Image")
    
  }

  const handleAdImageChange = e => {
    const file = e.target.files[0]
    const storageRef = ref(storage, `adImages/${Date.now()}${file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on("state_changed", (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setUploadProgressApp(progress)
    }, (error) => {
      toast.error(error.message)
    }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        const prevState = welcomeAdImage.imageURL.slice()
        prevState.push({imageURL: downloadURL})
        const newObj = { ...welcomeAdImage, imageURL: prevState };
        setWelcomeAdImage(newObj)
        toast.success("Image Uploaded Successfully.")
      })
    })
  }

  const confirmDeleteAdImage = ({imageURL}) => {
    Notiflix.Confirm.show(
      'DELETE IMAGE',
      'Are you sure you want to delete this image?',
      'DELETE',
      'CANCEL',
      function okCb() {
        deleteAdImage({imageURL});
      },
      function cancelCb() {
        console.log("Delete Canceled")
      },
      {
        width: '450px',
        borderRadius: '1px',
        titleColor: 'black',
        okButtonBackground: 'darkred',
        cssAnimationStyle: 'zoom'
      }

    )
  }
  const deleteAdImage = async ({imageURL}) => {
    const storageRef = ref(storage, imageURL)
    await deleteObject(storageRef)
    const prevState = welcomeAdImage.imageURL.slice()
    const findIndex = prevState.findIndex((item) => item.imageURL === imageURL)
    const newState = prevState.splice(0,findIndex)
    const newObj = { ...welcomeAdImage, imageURL: newState };
    setWelcomeAdImage(newObj)
    toast.success("Deleted Image")
    
  }

  const saveSettings = (e) => {
    e.preventDefault()
    setLoading(true)
    
    try{
      setDoc(doc(db, "settings", 'coverWebImage'),{
        imageURL: coverWebImage.imageURL,
      })
      setDoc(doc(db, "settings", 'coverAppImage'),{
        imageURL: coverAppImage.imageURL,
      })
      setDoc(doc(db, "settings", 'welcomeAdImage'),{
        imageURL: welcomeAdImage.imageURL,
      })
      setLoading(false)
      toast.success("Product Edited Successfully.")
      navigate('/admin/home')
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
    }
  }
  const handleInputCategory = e => {
    setInputCategory(e.target.value)
  }

  const handleInputSubCategory = e => {
    setInputSubCategory(e.target.value)
  }

  const handleInputSubCategoryTH = e => {
    setInputSubCategoryTH(e.target.value)
  }

  const deleteSubCategory = ({name, cat}) => {
    const categoryIndex = category?.mainCategory.findIndex((item) => item.category === cat)
    const subCategoryIndex = category?.mainCategory[categoryIndex].subCategory.findIndex((item) => item.name === name)
    category?.mainCategory[categoryIndex].subCategory.splice(subCategoryIndex, 1)
    try {
      setDoc(doc(db, "settings", 'category'),{
        mainCategory: category.mainCategory,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmitCategory = () => {
    const categoryIndex = category?.mainCategory.findIndex((item) => item.category === inputCategory)
    category?.mainCategory[categoryIndex].subCategory.push({name: inputSubCategory, name_th: inputSubCategoryTH})
    console.log(categoryIndex)
    try {
      setDoc(doc(db, "settings", 'category'),{
        mainCategory: category.mainCategory,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleExpressOptional = (e) => { 
    try{
      setDoc(doc(db, "settings", 'shippingMethod'),{
        express: e,
        expressPickup: expressPickup,
        pickup: pickup,
      })
      setExpress(e);
      toast.success("Edited")
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleExpressPickupOptional = (e) => { 
    try{
      setDoc(doc(db, "settings", 'shippingMethod'),{
        express: express,
        expressPickup: e,
        pickup: pickup,
      })
      setExpressPickup(e);
      toast.success("Edited")
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handlePickupOptional = (e) => { 
    try{
      setDoc(doc(db, "settings", 'shippingMethod'),{
        express: express,
        expressPickup: expressPickup,
        pickup: e,
      })
      setPickup(e);
      toast.success("Edited")
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <div>
        <h2>Settings</h2>
        <form onSubmit={saveSettings}>
          <div className={styles.webcoverdiv}>
            <h4>Website Cover Images</h4>
              {uploadProgress === 0 ? null :(
                  <div className={styles.progress}>
                    <div className={styles["progress-bar"]} style={{width: `${uploadProgress}%`}}>
                      {uploadProgress < 100 ? `Uploading ${uploadProgress}%` : `Upload Complete ${uploadProgress}%`}
                    </div>
                  </div>
                )}
                <input type="file" accept="image/*" placeholder="Cover Web Image" name="image" onChange={(e) => handleWebImageChange(e)} />

                {coverWebImage && coverWebImage.imageURL.map((image, index) => {
                  const {imageURL} = image
                  return (
                    <div className={styles.productimage} key={index}>
                      <img src={imageURL} alt={index} style={{ width: "100px"}}/>
                      <div className={styles.deletebutton} >
                        <p onClick={() => confirmDeleteWebImage({imageURL})}>DELETE</p>
                      </div>
                    </div>
                  )
                })}
          </div>
        
          <div>
            <h4>APP Cover Images</h4>
            {uploadProgressApp === 0 ? null :(
                  <div className={styles.progress}>
                    <div className={styles["progress-bar"]} style={{width: `${uploadProgressApp}%`}}>
                      {uploadProgressApp < 100 ? `Uploading ${uploadProgressApp}%` : `Upload Complete ${uploadProgressApp}%`}
                    </div>
                  </div>
                )}
                <input type="file" accept="image/*" placeholder="Cover App Image" name="image" onChange={(e) => handleAppImageChange(e)} />

                {coverAppImage && coverAppImage.imageURL.map((image, index) => {
                  const {imageURL} = image
                  return (
                    <div className={styles.productimage} key={index}>
                      <img src={imageURL} alt={index} style={{ width: "100px"}}/>
                      <div className={styles.deletebutton} >
                        <p onClick={() => confirmDeleteAppImage({imageURL})}>DELETE</p>
                      </div>
                    </div>
                  )
                })}
          </div>

          <div>
            <h4>Welcome Ad Images</h4>
            {uploadProgressApp === 0 ? null :(
                  <div className={styles.progress}>
                    <div className={styles["progress-bar"]} style={{width: `${uploadProgressApp}%`}}>
                      {uploadProgressApp < 100 ? `Uploading ${uploadProgressApp}%` : `Upload Complete ${uploadProgressApp}%`}
                    </div>
                  </div>
                )}
                <input type="file" accept="image/*" placeholder="Welcome Ad Image" name="image" onChange={(e) => handleAdImageChange(e)} />

                {welcomeAdImage && welcomeAdImage.imageURL.map((image, index) => {
                  const {imageURL} = image
                  return (
                    <div className={styles.productimage} key={index}>
                      <img src={imageURL} alt={index} style={{ width: "100px"}}/>
                      <div className={styles.deletebutton} >
                        <p onClick={() => confirmDeleteAdImage({imageURL})}>DELETE</p>
                      </div>
                    </div>
                  )
                })}
          </div>

          <button className="--btn --btn-primary">SAVE IMAGES</button>
          <div className={styles.category}>
            <h2>Category</h2>
              <div className={styles.list}>
                {category?.mainCategory ? (
                  category.mainCategory.map((item, index) => {
                    const {category, subCategory} = item;
                    return (
                      <div key={index}>
                        <h4>{category.toUpperCase()}</h4>
                        {subCategory?.map((item, index) => {
                          const {name, name_th} = item;
                          return (
                            <div key={index} className={styles.subCategory}>
                            <p>{name}</p>
                            <p>{name_th}</p>
                            <span onClick={() => deleteSubCategory({name, cat: category})}>DELETE</span>
                            </div>
                          )
                        })}
                        
                      </div>
                    )
                  })
                ) : null}
                
                <div className={styles.adddialog}>
                  
                  <h4>Add Sub Category</h4>
                  <select name="category" value={inputCategory} onChange={e => handleInputCategory(e)}>
                    <option value="" disabled>
                      -- choose product category --
                    </option>
                    {category?.mainCategory.map((item, index) => {
                      const {category} = item;
                      return(
                        <option key={index} value={category}>
                          {category.toUpperCase()}
                        </option> 
                      )
                    })}
                  </select>
                  <p>EN</p>
                  <input type='text' value={inputSubCategory} onChange={e => handleInputSubCategory(e)}></input>
                  <p>TH</p>
                  <input type='text' value={inputSubCategoryTH} onChange={e => handleInputSubCategoryTH(e)}></input>
                  <div className={styles.adddiv}>
                    <span className={styles.addbutton} onClick={() => handleSubmitCategory()}>ADD</span>
                  </div>
                </div>
              </div>
          </div>
          <div className={styles.shippingmethod}>
            <h4>Shipping Method</h4>
            <div className={styles.radioform}>
              <p>Express Delivery</p>
              <div className={styles.yesno}>
                <input type='radio' id='on' name='express' checked={express === true} onChange={() => handleExpressOptional(true)} />
                <p className={styles.textyesno}>On</p>
                <input type='radio' id='off' name='express'  checked={express === false} onChange={() => handleExpressOptional(false)} />
                <p className={styles.textyesno}>Off</p>
              </div>
            </div>
            <div className={styles.radioform}>
              <p>Express Pickup Delivery</p>
              <div className={styles.yesno}>
                <input type='radio' id='on' name='expresspickup' checked={expressPickup === true} onChange={() => handleExpressPickupOptional(true)} />
                <p className={styles.textyesno}>On</p>
                <input type='radio' id='off' name='expresspickup'  checked={expressPickup === false} onChange={() => handleExpressPickupOptional(false)} />
                <p className={styles.textyesno}>Off</p>
              </div>
            </div>
            <div className={styles.radioform}>
              <p>Pick Up</p>
              <div className={styles.yesno}>
                <input type='radio' id='on' name='pickup' checked={pickup === true} onChange={() => handlePickupOptional(true)} />
                <p className={styles.textyesno}>On</p>
                <input type='radio' id='off' name='pickup'  checked={pickup === false} onChange={() => handlePickupOptional(false)} />
                <p className={styles.textyesno}>Off</p>
              </div>
            </div>
          </div>
          
        </form>
        
    </div>
  )
}

export default Settings