import { addDoc, collection, doc, setDoc, Timestamp } from 'firebase/firestore'
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import Notiflix from 'notiflix'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { db, storage } from '../../../firebase/config'
import { selectProducts } from '../../../redux/slice/productSlice'
import Card from '../../card/Card'
import Loader from '../../loader/Loader'
import styles from "./AddProducts.module.scss"


const categories = [
  {id: 1,name: "packaging"},
  {id: 2,name: "fruit"},
  {id: 3,name: "frozen"},
]

const initialState = {
    sku: "",
    name: "",
    name_th: "",
    imageURL: [],
    stock: 0,
    price: 0,
    normalPrice: 0,
    category: "",
    subcategory: "",
    subcategory_th: "",
    desc: "",
    desc_th: "",
    optional: false,
    express: false,
    optionalList: [],
    discount: false,

}

const AddProducts = () => {
  const {id} = useParams()
  const products = useSelector(selectProducts)
  const productEdit = products.find((item) => item.id === id)

  const [product, setProduct] = useState(() => {
    const newState = detechForm(id, { ...initialState}, productEdit)
    return newState
  })

  const [uploadProgress, setUploadProgress] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [optional, setOptional] = useState(null);
  const [optionalExpress, setOptionalExpress] = useState(null);
  const [optionalList, setOptionalList] = useState('');
  const [optionalListTH, setOptionalListTH] = useState('');
  const [discount, setDiscount] = useState(false);

  useEffect(() => {
    if (product?.optional === true) {
      setOptional(true)
    } else if (product?.optional === false) {
      setOptional(false)
    }
  }, [product])

  useEffect(() => {
    if (product?.express === true) {
      setOptionalExpress(true)
    } else if (product?.express === false) {
      setOptionalExpress(false)
    }
  }, [product])

  useEffect(() => {
    if (product?.discount === true) {
      setDiscount(true)
    } else if (product?.discount === false) {
      setDiscount(false)
    }
  }, [product])



  function detechForm(id, f1, f2) {
    if (id === "ADD"){
      return f1;
    }
    return f2

  }

  const handleInputChange = (e) => {
    const {name, value} = e.target
    setProduct({...product, [name]: value})
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    const storageRef = ref(storage, `images/${Date.now()}${file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on("state_changed", (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setUploadProgress(progress)
    }, (error) => {
      toast.error(error.message)
    }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        const prevState = product.imageURL.slice()
        prevState.push({imageURL: downloadURL})
        const newObj = { ...product, imageURL: prevState };
        setProduct(newObj)
        toast.success("Image Uploaded Successfully.")
      })
    })
  }

  const addProduct = (e) => {
    e.preventDefault()
    setIsLoading(true)  
    try{
      const docRef = addDoc(collection(db, "products"), {
        sku: product.sku,
        name: product.name,
        name_th: product.name_th,
        imageURL: product.imageURL,
        price: Number(product.price),
        normalPrice: Number(product.normalPrice),
        stock: Number(product.stock),
        stockControl: product.stockControl ? product.stockControl : [],
        category: product.category,
        subcategory: product.subcategory,
        subcategory_th: product.subcategory_th,
        desc: product.desc,
        desc_th: product.desc_th,
        optional: product.optional,
        optionalList: product.optionalList,
        express: product.express,
        discount: product.discount,
        createdAt: Timestamp.now().toDate()
      })
      setIsLoading(false)
      setUploadProgress(0)
      setProduct({...initialState})
      toast.success("Product uploaded successfully.")
      navigate("/admin/all-products")
    } catch (error) {
      setIsLoading(false)
      toast.error(error.message)
    }
  }

  const editProduct = (e) => {
    e.preventDefault()
    setIsLoading(true)
    
    try{
      setDoc(doc(db, "products", id),{
        sku:product.sku,
        name: product.name,
        name_th: product.name_th,
        imageURL: product.imageURL,
        price: Number(product.price),
        normalPrice: Number(product.normalPrice) ? Number(product.normalPrice) : 0,
        stock: Number(product.stock),
        stockControl: product.stockControl,
        category: product.category,
        subcategory: product.subcategory,
        subcategory_th: product.subcategory_th,
        desc: product.desc,
        desc_th: product.desc_th,
        optional: product.optional,
        optionalList: product.optionalList ? product.optionalList : [],
        express: product.express,
        discount: product.discount ? product.discount : null,
        createdAt: productEdit.createdAt,
        editedAt: Timestamp.now().toDate()
      }, {merge: true})
      setIsLoading(false)
      toast.success("Product Edited Successfully.")
      navigate("/admin/all-products")
    } catch (error) {
      setIsLoading(false)
      toast.error(error.message)
    }
  }
  const confirmDeleteImage = ({imageURL}) => {
    Notiflix.Confirm.show(
      'DELETE IMAGE',
      'Are you sure you want to delete this image?',
      'DELETE',
      'CANCEL',
      function okCb() {
        deleteImage({imageURL});
      },
      function cancelCb() {
        console.log("Delete Canceled")
      },
      {
        width: '450px',
        borderRadius: '1px',
        titleColor: 'black',
        okButtonBackground: 'darkred',
        cssAnimationStyle: 'zoom'
      }

    )
  }
  const deleteImage = async ({imageURL}) => {
    const storageRef = ref(storage, imageURL)
    await deleteObject(storageRef)
    const prevState = product.imageURL.slice()
    const findIndex = prevState.findIndex((item) => item.imageURL === imageURL)
    const newState = prevState.splice(0,findIndex)
    const newObj = { ...product, imageURL: newState };
    setProduct(newObj)
    toast.success("Deleted Image")
    
  }
  
  const handleOptional = e => {
    setOptional(e);
    setProduct({...product, optional: e})
  }

  const handleOptionalExpress = e => {
    setOptionalExpress(e);
    setProduct({...product, express: e})
  }

  const handleAddOptionalList = () => {
    const prevState = product.optionalList.slice()
    prevState.push({name : optionalList, name_th: optionalListTH})
    setProduct({...product, optionalList: prevState})
    console.log(product)
  }

  const deleteOptionalList = name => {
    const optionalIndex = product.optionalList.findIndex((item) => item.name === name)
    const prevState = product.optionalList.slice()
    prevState.splice(optionalIndex, 1)
    setProduct({...product, optionalList: prevState})
  }

  const handleOptionalStock = (e, name, name_th) => {
    const optionalIndex = product.optionalList.findIndex((item) => item.name === name)
    const prevState = product.optionalList.slice()
    prevState.splice(optionalIndex, 1, {name: name, name_th: name_th, stock: e.target.value})
    setProduct({...product, optionalList: prevState})
  }

  const handleDiscount = e => {
    setDiscount(e);
    setProduct({...product, discount: e})
  }

  return (
    <>
    {isLoading && <Loader />}
      <div className={styles.product}>
        <h2>{detechForm(id, "Add New Product", "Edit Product")}</h2>
        <br />
        <Card cardClass={styles.card}>
          <form onSubmit={detechForm(id, addProduct, editProduct)}>
            <label>SKU</label>
            <input type="text" placeholder="SKU" name="sku" value={product.sku} required onChange={(e) => handleInputChange(e)} />
            <label>Product Name</label>
            <br />
            <p>EN</p>
            <input type="text" placeholder="Product name" required name="name" value={product.name} onChange={(e) => handleInputChange(e)} />
            <p>TH</p>
            <input type="text" placeholder="Product name TH" required name="name_th" value={product.name_th} onChange={(e) => handleInputChange(e)} />
            <label>Product Image</label>
            <br />
              {uploadProgress === 0 ? null :(
                <div className={styles.progress}>
                  <div className={styles["progress-bar"]} style={{width: `${uploadProgress}%`}}>
                    {uploadProgress < 100 ? `Uploading ${uploadProgress}%` : `Upload Complete ${uploadProgress}%`}
                  </div>
                </div>
              )}
              <input type="file" accept="image/*" placeholder="Product Image" name="image" onChange={(e) => handleImageChange(e)} />

              {product && product.imageURL.map((image, index) => {
                const {imageURL} = image
                return (
                  <div className={styles.productimage} key={index}>
                    <img src={imageURL} alt={index} style={{ width: "100px"}}/>
                    <div className={styles.deletebutton} >
                      <p onClick={() => confirmDeleteImage({imageURL})}>DELETE</p>
                    </div>
                  </div>
                )
              })}
            
            <br />
            <div className={styles.radioform}>
              <label>Discount Setting</label>
              <div className={styles.yesno}>
                <input type='radio' id='yes' name='discount' checked={discount === true} onChange={() => handleDiscount(true)} />
                <p className={styles.textyesno}>Yes</p>
                <input type='radio' id='no' name='discount'  checked={discount === false} onChange={() => handleDiscount(false)} />
                <p className={styles.textyesno}>No</p>
              </div>
            </div>
            <label>{discount === true ? 'Discount Price' : 'Price'}</label>
            <input type="number" placeholder="Product price" required name="price" value={product.price} onChange={(e) => handleInputChange(e)} />
            {discount === true ? (
              <div>
                <label>Normal Price</label>
                <input type="number" placeholder="Normal price" name="normalPrice" value={product.normalPrice} onChange={(e) => handleInputChange(e)} />
              </div>
            ) : null}
            <label>Stock Available</label>
            <input type="number" placeholder="Total stock" required name="stock" value={product.stock} onChange={(e) => handleInputChange(e)} />
            <label>Product Category</label>
            <select required name="category" value={product.category} onChange={(e) => handleInputChange(e)}>
              <option value="" disabled>
                Choose Product Category
              </option>
              {categories.map((cat) => {
                return(
                  <option key={cat.id} value={cat.name}>
                    {cat.name}
                  </option> 
                )
              })}
            </select>
            <label>Product Subcategory</label>
            <br />
            <p>EN</p>
            <input type="text" placeholder="Product Subcategory" required name="subcategory" value={product.subcategory} onChange={(e) => handleInputChange(e)} />
            <p>TH</p>
            <input type="text" placeholder="Product Subcategory TH" required name="subcategory_th" value={product.subcategory_th} onChange={(e) => handleInputChange(e)} />
            <label>Product Description</label>
            <br />
            <p>EN</p>
            <textarea name="desc" required value={product.desc} onChange={(e) => handleInputChange(e)} cols="30" rows="10"></textarea>
            <p>TH</p>
            <textarea name="desc_th" required value={product.desc_th} onChange={(e) => handleInputChange(e)} cols="30" rows="10"></textarea>
            
            <div className={styles.radioform}>
              <label>Optional</label>
              <div className={styles.yesno}>
                <input type='radio' id='yes' name='optional' checked={optional === true} onChange={() => handleOptional(true)} />
                <p className={styles.textyesno}>Yes</p>
                <input type='radio' id='no' name='optional'  checked={optional === false} onChange={() => handleOptional(false)} />
                <p className={styles.textyesno}>No</p>
              </div>
            </div>
            {optional ? (
              <div className={styles.optionallist}>
                <label>Optional List</label>
                <br />
                {product?.optionalList.map((item, index) => {
                  const {name, name_th} = item;
                  return (
                    <div key={index}>
                      <p>{name}</p>
                      <p>{name_th}</p>
                      <p>Stock</p>
                      <input type='text' name='optionalstock' value={product?.optionalList[index].stock} onChange={(e) => handleOptionalStock(e, name, name_th)} />
                      <p onClick={() => deleteOptionalList(name)}>Delete</p>
                      <br />
                      <hr />
                      <br />
                    </div>
                  )
                })}
                <br />
                <label>Add Optional List</label>
                <p>EN</p>
                <input type='text' value={optionalList} onChange={(e) => setOptionalList(e.target.value)} />
                <p>TH</p>
                <input type='text' value={optionalListTH} onChange={(e) => setOptionalListTH(e.target.value)} />
                <div className={styles.addoptionalbutton}  onClick={() => handleAddOptionalList()}>
                  <span>ADD</span>
                </div>
              </div>
            ) : null}

            <div className={styles.radioform}>
              <label>Express</label>
              <div className={styles.yesno}>
                <input type='radio' id='yes' name='optionalExpress' checked={optionalExpress === true} onChange={() => handleOptionalExpress(true)} />
                <p className={styles.textyesno}>Yes</p>
                <input type='radio' id='no' name='optionalExpress'  checked={optionalExpress === false} onChange={() => handleOptionalExpress(false)} />
                <p className={styles.textyesno}>No</p>
              </div>
            </div>
            
            <button className="--btn --btn-primary">{detechForm(id, "Save Product", "Edit Product")}</button>
          </form>
        </Card>
      </div>
    </>
  )
}

export default AddProducts