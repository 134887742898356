import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { db } from "../firebase/config"
import { selectUserID } from "../redux/slice/authSlice"

const useFetchCollectionOrdersQR = () => {
    const [ordersQR, setOrdersQR] = useState([])
    const [isLoadingOrdersQR, setIsLoadingOrdersQR] = useState(false)
    const userID = useSelector(selectUserID);

    const getCollection = () => {
        setIsLoadingOrdersQR(true)
        try{
          const docRef = collection(db, 'ordersQR')
          const q = query(docRef, orderBy("createdAt","desc"))
          onSnapshot(q, (snapshot) => {
            const allData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data()
            }))
            allData.sort((a, b) => b.createdAt - a.createdAt)
            setOrdersQR(allData)
            setIsLoadingOrdersQR(false)
            
          })
        } catch (error) {
          setIsLoadingOrdersQR(false)
          toast.error(error.message)
        }
      }

      useEffect(() => {
        getCollection()
      },[userID])

      return {ordersQR, isLoadingOrdersQR}

}

export default useFetchCollectionOrdersQR