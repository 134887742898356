import { deleteDoc, doc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { db, storage } from '../../../firebase/config'
import styles from "./ViewProducts.module.scss"
import { FaEdit, FaTrashAlt } from "react-icons/fa"
import Loader from '../../loader/Loader'
import { deleteObject, ref } from 'firebase/storage'
import Notiflix from "notiflix"
import { useDispatch, useSelector } from 'react-redux'
import { selectPaginationPage, selectProducts, STORE_PAGINATION_PAGE, STORE_PRODUCTS } from '../../../redux/slice/productSlice'
import useFetchCollection from '../../../customHook/useFetchCollection'
import { FILTER_BY_CATEGORY, FILTER_BY_SEARCH, selectCategory, selectFilteredProduct, SORT_PRODUCTS } from '../../../redux/slice/filterSlice'
import Search from '../../search/Search'
import Pagination from '../../pagination/Pagination'

const ViewProducts = () => {
  const [search, setSearch] = useState("")
  const [category, setCategory] = useState("All")
  const [sort, setSort] = useState("latest")
  const { data, isLoading } = useFetchCollection("products")
  const products = useSelector(selectProducts)
  const filteredProducts = useSelector(selectFilteredProduct)
  const navigate = useNavigate();

  // Pagination states
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ productsPerPage, setProductsPerPage ] = useState(10)
  
  // Get Current Products
  const indexOfLastProduct = currentPage * productsPerPage
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct)

  const previousPage = useSelector(selectPaginationPage)
  const savedCategory = useSelector(selectCategory)

  const dispatch = useDispatch()

  const allCategory = [
    "All",
    ...new Set(products.map((product) => product.category))
  ]

  useEffect(() => {
    if (previousPage !== null) {
      setCurrentPage(previousPage)
      setCategory(savedCategory)
    } 
    else {
      setCurrentPage (1)
    }
  },[filteredProducts])

  useEffect(() => {
    dispatch(
              STORE_PRODUCTS({
                products: data
              })
              )
  },[dispatch, data])

  useEffect(() => {
    dispatch(SORT_PRODUCTS({products: products, sort}))
  },[dispatch, products, sort])

  useEffect(() => {
    dispatch(FILTER_BY_SEARCH({ products, search}))

  },[dispatch, products, search])

  useEffect(() => {
    dispatch(FILTER_BY_CATEGORY({products: products, category: category}))
  },[dispatch, products, category])


  const confirmDelete = (id, imageURL) => {
    Notiflix.Confirm.show(
      'Delete product.',
      'Are you sure to delete this product?',
      'Delete',
      'Cancel',
      function okCb() {
        deleteProduct(id, imageURL)
      },
      function cancelCb() {
        console.log("Delete Canceled")
      },
      {
        width: '320px',
        borderRadius: '8px',
        titleColor: 'orangered',
        okButtonBackground: 'orangered',
        cssAnimationStyle: 'zoom'
      }

    )

  }

  const editProduct = id => {
    dispatch(STORE_PAGINATION_PAGE(currentPage))
    navigate(`/admin/add-product/${id}`)
  }

  const deleteProduct = async (id, imageURL) => {
    try{
      await deleteDoc(doc(db, "products", id))
      for (let i=0; i < imageURL.length; i++) {
        const array = imageURL[i]
        const storageRef = ref(storage, array.imageURL)
        await deleteObject(storageRef)
      }
      
      toast.success("Product deleted successfully.")
    } catch (error) {
      toast.error(error.message)
    }
    


  }

  return (
    <>
    {isLoading && <Loader />}
    <div className={styles.table}>
      <h3>ALL PRODUCT</h3>
      <div className={styles.search}>
        <p>
          <b>{filteredProducts.length}</b> products found
        </p>
        <Search value={search} onChange={(e) => setSearch(e.target.value)}/>
        <br />
        <select value={category} onChange={(e) => setCategory(e.target.value)}>
          {allCategory.map((category, index) => {
            return (
              <option key={index} value={category}>{category.toUpperCase()}</option>
            )
          })}
          
        </select>
        <div className={styles.sort}>
          <br />
          <select value={sort} onChange={(e) => setSort(e.target.value)}>
            <option value="latest">LATEST</option>
            <option value="lowest-price">LOWEST PRICE</option>
            <option value="highest-price">HIGHEST PRICE</option>
            <option value="a-z">A - Z</option>
            <option value="z-a">Z - A</option>
            <option value="sku">SKU</option>
          </select>
        </div>

      </div>
      {filteredProducts.length === 0 ? (
        <p>No product found.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>SKU</th>
              <th></th>
              <th>Name</th>
              <th>Category</th>
              <th>Sub Category</th>
              <th>Price</th>
              <th>In Stock</th>
              <th>Optional</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentProducts.map((product, index) => {
              const {id, name, name_th, price, imageURL, category, subcategory, subcategory_th, stock, sku, optional, express, discount, normalPrice} = product
              return (
                <tr key={id}>
                  <td>
                    {sku}
                  </td>
                  <td>
                    <img src={imageURL[0].imageURL} alt={name} style={{width: "100px"}}/>
                  </td>
                  <td>
                    <p>
                      <b>
                        {name}
                      </b>
                    </p>
                    <p>{name_th}</p>
                  </td>
                  <td>
                    {category}
                  </td>
                  <td>
                    <p>{subcategory}</p>
                    <p>{subcategory_th}</p>
                  </td>
                  <td>
                    <p className={discount === true ? styles.discountprice : null}>
                      {`${price} THB`}
                    </p>
                    {discount === true ? (
                      <p className={styles.normalprice}>
                        {`${normalPrice} THB`}
                      </p>
                    ) : null}
                  </td>
                  <td>
                    {stock}
                  </td>
                  <td>
                    {optional === true ? 'yes' : optional === false ? 'no' : null}
                    <div>
                      <p style={{fontWeight: '500', paddingTop: 20, color: 'red'}}>
                        Express
                      </p>
                      <p>
                        {express === true ? 'yes' : express === false ? 'no' : null}
                      </p>
                    </div>
                   
                  </td>
                  <td className={styles.icons}>
                    <span onClick={() => editProduct(id)}>
                      <FaEdit size={20} color="black" />
                    </span>
                    &nbsp;
                    <FaTrashAlt size={20} color="red" onClick={() => confirmDelete(id, imageURL)} />

                  </td>
                </tr>
            )
          })}
          </tbody>
        </table>
      )}
      <Pagination 
        currentPage={currentPage} 
        setCurrentPage={setCurrentPage}
        productsPerPage={productsPerPage} 
        totalProducts={filteredProducts.length}
      />

    </div>
    
    </>
  )
}

export default ViewProducts