import React, { useEffect, useState } from 'react'
import styles from './CreateNewStock.module.scss'
import dayjs from 'dayjs';
import useFetchCollection from '../../../customHook/useFetchCollection';
import Loader from '../../loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { STORE_PRODUCTS, selectPaginationPage, selectProducts } from '../../../redux/slice/productSlice';
import { FILTER_BY_CATEGORY, FILTER_BY_SEARCH, SORT_PRODUCTS, selectCategory, selectFilteredProduct } from '../../../redux/slice/filterSlice';
import { Timestamp, addDoc, collection, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { FaEdit, FaPlus } from 'react-icons/fa';
import Search from '../../search/Search';
import { Box, Button, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const CreateNewStock = () => {
    const [search, setSearch] = useState("")
    const [category, setCategory] = useState("All")
    const [sort, setSort] = useState("latest")
    const [selectedBranch, setSelectedBranch] = useState('kallapaprueak');
    const [todayDate, setTodayDate] = useState('');
    const [tempAdded, setTempAdded] = useState([]);
    const { data, isLoading } = useFetchCollection("products");
    const products = useSelector(selectProducts);
    const filteredProducts = useSelector(selectFilteredProduct);
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();


    // Pagination states
    const [ currentPage, setCurrentPage ] = useState(1)
    const [ productsPerPage, setProductsPerPage ] = useState(20)
    
    // Get Current Products
    const indexOfLastProduct = currentPage * productsPerPage
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct)
    
    const previousPage = useSelector(selectPaginationPage)
    const savedCategory = useSelector(selectCategory)
    
    const dispatch = useDispatch()
    
    const allCategory = [
        "All",
        ...new Set(products.map((product) => product.category))
    ]
    
    useEffect(() => {
        const today = dayjs(new Date()).format('DD/MM/YYYY');
        setTodayDate(today)
    }, [])

    useEffect(() => {
        if (previousPage !== null) {
        setCurrentPage(previousPage)
        setCategory(savedCategory)
        } 
        else {
        setCurrentPage (1)
        }
    },[filteredProducts])
    
    useEffect(() => {
        dispatch(
                STORE_PRODUCTS({
                    products: data
                })
                )
    },[dispatch, data])
    
    useEffect(() => {
        dispatch(SORT_PRODUCTS({products: products, sort}))
    },[dispatch, products, sort])
    
    useEffect(() => {
        dispatch(FILTER_BY_SEARCH({ products, search}))
    
    },[dispatch, products, search])
    
    useEffect(() => {
        dispatch(FILTER_BY_CATEGORY({products: products, category: category}))
    },[dispatch, products, category])

    const handleQtyChange = (e, id) => {
        const tempFindIndex = tempAdded.findIndex(item => item.id === id)
        if (tempFindIndex !== -1) {
            const arr = [...tempAdded]
            arr[tempFindIndex].qty = Number(e.target.value)
            setTempAdded(arr)
        }
    };

    const addToTemp = (id, name) => {
        const tempFindIndex = tempAdded.findIndex(item => item.id === id)
        if (tempFindIndex === -1) {
            const arr = [...tempAdded];
            arr.push({id: id, name: name, qty: 1})
            setTempAdded(arr)
        }
        
    }

    const confirmNewStock = () => {
        setOpen(true);
    }

    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const SaveToFirestore = () => {
        addDoc(collection(db, "newstock"), {
            branch: selectedBranch,
            date: todayDate,
            itemList : tempAdded,
            createdAt: Timestamp.now().toDate()
        }).then(() => {
            navigate('../newstockmng')
            toast.success('Create New Stock Successful!')
        })
    }

  return (
    <>
    {isLoading && <Loader />}
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                <h4>Confirm New Stock</h4>
                <p>Branch : {selectedBranch}</p>
                <p>Date : {todayDate}</p>
                <p style={{paddingTop: 20, paddingBottom: 10}}>
                    <b>Item List</b>
                </p>
                {tempAdded.map((item, index) => {
                    const {name, qty} = item;
                    return (
                        <div key={index}>
                            <p>{name} x {qty}</p>
                        </div>
                       
                    )
                })}
                <div style={{paddingTop: 20}}>
                    <Button variant='contained' onClick={SaveToFirestore}>Save</Button>
                </div>
            </Box>
        </Modal>
        <div className={styles.createnewstock}>
            <h4>Create New Stock</h4>
            <div className={styles.leftdiv}>
                <p style={{paddingTop: 15}}>
                    Select branch to create new stock
                </p>
                <div className={styles.branchselect}>
                    <select value={selectedBranch} onChange={(e) => setSelectedBranch(e.target.value)}>
                    <option value="kallapaprueak">Kallapaprueak Branch</option>
                    </select>
                </div>
                <p style={{paddingBottom: 20}}>Stock will be create on 
                    <b style={{paddingLeft: 5}}>{todayDate}</b>
                </p>
                <hr />
                <p style={{paddingTop: 20, paddingBottom: 20}}>
                    <b>Select item to create stock</b>
                </p>
                <div className={styles.search}>
                    <Search value={search} onChange={(e) => setSearch(e.target.value)}/>
                </div>
          
                <div className={styles.filter}>
                    <div className={styles.sortcategory}>
                        <select value={category} onChange={(e) => setCategory(e.target.value)}>
                            {allCategory.map((category, index) => {
                            return (
                                <option key={index} value={category}>{category.toUpperCase()}</option>
                            )
                            })}
                            
                        </select>
                    </div>
                    
                    <div className={styles.sort}>
                        <select value={sort} onChange={(e) => setSort(e.target.value)}>
                            <option value="latest">LATEST</option>
                            <option value="lowest-price">LOWEST PRICE</option>
                            <option value="highest-price">HIGHEST PRICE</option>
                            <option value="a-z">A - Z</option>
                            <option value="z-a">Z - A</option>
                            <option value="sku">SKU</option>
                        </select>
                    </div>
                </div>
                
                <div className={styles.filterdesc}>
                    <p>
                        <b>{filteredProducts.length}</b> products found
                    </p>
                </div>
            </div>
            {filteredProducts.length === 0 ? (
                <p>No product found.</p>
            ) : (
                <table>
                    <tbody>
                    {currentProducts.map((product, index) => {
                        const {id, name, name_th, price, imageURL, stock, express, stockControl} = product;
                        return (
                        <tr key={id}>
                            <td>
                                <img src={imageURL[0].imageURL} alt={name} style={{width: "100px"}}/>
                            </td>
                            <td>
                                <p>
                                    <b>{name}</b>
                                </p>
                                <p>
                                    {name_th}
                                </p>
                                <p style={{paddingTop: 20}}>
                                    {`${price} THB`}
                                </p>
                            </td>
                            <td>
                                {tempAdded.findIndex(item => item.id === id) >= 0 ? (
                                    <>
                                    
                                        <p style={{fontWeight: '500', color: 'black', paddingBottom: 10}}>
                                            Stock To Add
                                        </p>
                                        <input type='number' value={tempAdded.find(item => item.id === id).qty} key={index} onChange={(e) => handleQtyChange(e, id)} />
                                    </>
                                ) : (
                                    <span className={styles.addbutton} onClick={() => addToTemp(id, name)}>
                                        <FaPlus />
                                    </span>
                                )}
                                
                                
                                
                                
                            </td>
                        </tr>
                    )
                    })}
                    </tbody>
                </table>
            )}
            <div className={styles.savebutton}>
                <Button variant='contained' onClick={confirmNewStock} disabled={tempAdded.length === 0}>
                    Save
                </Button>
            </div>
            

        </div>
    </>
  )
}

export default CreateNewStock