import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    shippingAddress: {},
    billingAddress: {},
    pickupDate: {},
    pickupTime: {},
    noteMsg: '',

}

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    SAVE_SHIPPING_ADDRESS(state,action) {
      state.shippingAddress = action.payload
    },
    SAVE_BILLING_ADDRESS(state,action) {
      state.billingAddress = action.payload
    },
    SAVE_NOTE_MSG(state,action) {
      state.noteMsg = action.payload
    },
    SAVE_PICKUP_DATE(state, action) {
      state.pickupDate = action.payload
    },
    SAVE_PICKUP_TIME(state, action) {
      state.pickupTime = action.payload
    },

  }
});

export const {SAVE_BILLING_ADDRESS, SAVE_SHIPPING_ADDRESS, SAVE_PICKUP_DATE, SAVE_PICKUP_TIME, SAVE_NOTE_MSG} = checkoutSlice.actions

export const selectShippingAddress = (state) => state.checkout.shippingAddress
export const selectBillingAddress = (state) => state.checkout.billingAddress
export const selectPickupDate = (state) => state.checkout.pickupDate
export const selectPickupTime = (state) => state.checkout.pickupTime
export const selectNoteMsg = (state) => state.checkout.noteMsg

export default checkoutSlice.reducer