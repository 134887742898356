import { initReactI18next } from 'react-i18next';
import './App.css';
import i18n from "i18next";
import translationEN from './locales/en/translation.json';
import translationTH from './locales/th/translation.json';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminOnlyRoute from './components/adminOnlyRoute/AdminOnlyRoute';
import Admin from './pages/admin/Admin';
import Login from './pages/auth/Login';
import NotFound from './pages/notFound/NotFound';
import { ApolloProvider } from '@apollo/client';
import client from "./utils/ApolloClient";
import Header from './components/header/Header';
import Home from './pages/home/Home';

function App() {
  i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN } ,
      th: { translation: translationTH },
    },
    lng: localStorage.getItem("lng") || "en",
    fallbackLng: "en",
    interpolation: { escapeValue: false },
  });
  return (
  <>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ToastContainer />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/admin/*"
            element={
              <AdminOnlyRoute>
                <Admin />
              </AdminOnlyRoute>
              }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  </>
  );
}

export default App;
