import { collection, limit, onSnapshot, orderBy, query } from "firebase/firestore"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { db } from "../firebase/config"

const useFetchCollectionNewStock = () => {
    const [newStock, setNewStock] = useState([])
    const [isLoadingNewStock, setIsLoadingNewStock] = useState(false)

    const getCollection = () => {
        setIsLoadingNewStock(true)
        try{
          const docRef = collection(db, 'newstock')
          const q = query(docRef, orderBy("createdAt","desc"), limit(100))
          onSnapshot(q, (snapshot) => {
            const allData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data()
            }))
            setNewStock(allData)
            setIsLoadingNewStock(false)
            
          })
        } catch (error) {
            setIsLoadingNewStock(false)
          toast.error(error.message)
        }
      }

      useEffect(() => {
        getCollection()
      },[])

      return {newStock, isLoadingNewStock}

}

export default useFetchCollectionNewStock