import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck , ReCaptchaV3Provider} from "firebase/app-check";
import { getAuth } from "firebase/auth";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: "butter-and-egg.firebaseapp.com",
  projectId: "butter-and-egg",
  storageBucket: "butter-and-egg.appspot.com",
  messagingSenderId: "1089317629303",
  appId: "1:1089317629303:web:88861572a8e2fe14da0572",
  measurementId: "G-2JTDVQYF17"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app)
export const storage = getStorage(app);

export default app

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LeqKccpAAAAACXkpKMoCnsRkdCugSySDsGwl7NY'),
  isTokenAutoRefreshEnabled: true,
});
