import React from 'react'
import { FaUserCircle } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import styles from "./Navbar.module.scss"

const activeLink = ({ isActive }) => (isActive ? `${styles.active}` : "");

const Navbar = () => {

  return (
    <div className={styles.navbar}>
        <div className={styles.user}>
            <FaUserCircle size={40} color="black"/>
        </div>
        <nav>
            <ul>
                <li>
                    <NavLink to="/admin/">
                        Dashboard
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/admin/all-products" className={activeLink}>
                        All product
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/admin/add-product/ADD" className={activeLink}>
                        Add New Product
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/admin/orders" className={activeLink}>
                        Total Orders
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/admin/settings" className={activeLink}>
                        Settings
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/admin/editpolicy" className={activeLink}>
                        Edit Policy
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/admin/postmanagement" className={activeLink}>
                        Post Management
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/admin/blogmanagement" className={activeLink}>
                        Blog Management
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/admin/reviewmanagement" className={activeLink}>
                        Review Management
                    </NavLink>
                </li>
                <p className={styles.title}>Branch Management</p>
                <li>
                    <NavLink to="/admin/stockcontrol" className={activeLink}>
                        Stock Control
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/admin/newstockmng" className={activeLink}>
                        New Stock Management
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/admin/storesettings" className={activeLink}>
                        Store Settings
                    </NavLink>
                </li>
            </ul>

        </nav>
    </div>
  )
}

export default Navbar